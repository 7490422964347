import React, { Fragment, useEffect, useState } from "react";
import Swal from "sweetalert2";
import M from "materialize-css";

import Baner from "../Baner/Baner";
import BanerContador from "../Baner/BanerContador";

import BanersHelper from "../../Helpers/Baners";

import "./Header.css";

import logo from "../../Images/logos/1.svg";

const Header = () => {
  const [baner, setBaner] = useState(false);
  const [banerContador, setBanerContador] = useState(false);
  const [fecha, setFecha] = useState("");
  const [color, setColor] = useState("");

  useEffect(() => {
    var elems = document.querySelectorAll(".sidenav");
    M.Sidenav.init(elems);
    var elems2 = document.querySelectorAll(".dropdown-trigger");
    M.Dropdown.init(elems2, {
      hover: true,
      constrainWidth: false,
      coverTrigger: false,
      autoTrigger: true,
    });
    var elems3 = document.querySelectorAll(".collapsible");
    M.Collapsible.init(elems3);

    obtenerConfiguracionBaner();
  }, []);

  const obtenerConfiguracionBaner = async () => {
    // TODO: esta consulta debe de ser en un context para usarlo en multiples vistas y no hacer la consulta doble :v
    let res = await BanersHelper.obtenerConfiguracionBaners();

    if (res.success) {
      setColor(res.data.color);
      setFecha(res.data.fecha);
      setBanerContador(res.data.baner_oferta);
      setBaner(res.data.baners);
    } else {
      Swal.fire(
        "Error!",
        "No se lograron obtener la configuracion de los baners, codigo: " +
          res.code,
        "error"
      );
    }
  };

  const redireccion = (link) => {
    sessionStorage.setItem("__cv_pp__", true);
    window.location.replace(link);
  };

  return (
    <Fragment>
      <div className="invisible_movil" />
      <nav
        className="padding_header menu_header"
        data-className={baner || banerContador}
      >
        <div className="nav-wrapper">
          {baner && <Baner color={color} />}
          {banerContador && <BanerContador fecha={fecha} color={color} />}
          <div className="flex_header">
            <a
              href="#!"
              data-target="mobile-demo"
              className="sidenav-trigger"
              style={{ position: "absolute", left: "0px", marginTop: "-10px" }}
            >
              <i className="material-icons" style={{ color: "#00496C" }}>
                menu
              </i>
            </a>
            <div className="contendor_header">
              <a href="/">
                <p className="caav_header invisible_movil">
                  CAAV, UNIVERSIDAD DE MEDIOS AUDIOVISUALES
                </p>
                <img
                  className="invisible_escritorio"
                  src={logo}
                  alt="CAAV logo"
                  style={{ width: "150px", marginTop: "5px" }}
                />
              </a>
            </div>

            <ul className="menu_lista right hide-on-med-and-down header_2">
              <li className="hover_opciones">
                <a
                  className="dropdown-trigger"
                  href="#!"
                  data-target="dropdown1"
                >
                  CAAV
                </a>
              </li>
              <li className="hover_opciones">
                <a
                  className="dropdown-trigger"
                  href="#!"
                  data-target="dropdown2"
                >
                  Licenciaturas
                </a>
              </li>
              <li className="hover_opciones">
                <button
                  onClick={() => {
                    redireccion("/cursos-diplomados");
                  }}
                >
                  Cursos y diplomados
                </button>
              </li>
              <li className="hover_opciones">
                <a href="/beca-caav">Becas</a>
              </li>
              <li className="hover_opciones">
                <a href="/caav-blogs">Blog</a>
              </li>
              <li className="hover_opciones">
                <a href="/igualdad">Igualdad</a>
              </li>
              <li className="hover_opciones">
                <a href="/entorno">Entorno</a>
              </li>
              <li className="hover_opciones">
                <a href="/elcaavsemueve">Nueva sede</a>
              </li>
              <li className="hover_opciones">
                <a href="/proceso-admision">
                  <span
                    style={{
                      color: "#14ffff",
                      margin: "0",
                      border: "solid 1px #14ffff",
                      padding: "10px",
                      borderRadius: "10px",
                    }}
                  >
                    Cómo inscribirte
                  </span>
                </a>
              </li>
              <li className="hover_opciones">
                <a
                  className="dropdown-trigger"
                  href="#!"
                  data-target="dropdown3"
                  style={{ color: "#FFEC00" }}
                >
                  Estudiantes
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="menu_header_alto" />

      <ul className="sidenav" id="mobile-demo" style={{ zIndex: "9999" }}>
        <li>
          <ul className="collapsible">
            <li>
              <div
                className="collapsible-header header_3"
                style={{ padding: "0px 32px", color: "#000000de" }}
              >
                CAAV
              </div>
              <div className="collapsible-body red_caav">
                <p className="header_3">
                  <a href="/somos">Somos</a>
                </p>
                <span className="divider" tabindex="-1"></span>
                <p className="header_3">
                  <a href="/contacto">Contacto</a>
                </p>
                <span className="divider" tabindex="-1"></span>
                <p className="header_3">
                  <a href="/docentes">Docentes</a>
                </p>
              </div>
            </li>
          </ul>
        </li>
        <li>
          <ul className="collapsible">
            <li>
              <div
                className="collapsible-header header_3"
                style={{ padding: "0px 32px", color: "#000000de" }}
              >
                Licenciaturas
              </div>
              <div className="collapsible-body red_caav">
                <p className="header_3">
                  <a href="/licenciatura-en-cine">Cine Digital</a>
                </p>
                <span className="divider" tabindex="-1"></span>
                <p className="header_3">
                  <a href="/licenciatura-en-animacion">Animación</a>
                </p>
                <span className="divider" tabindex="-1"></span>
                <p className="header_3">
                  <a href="/multimedia">Multimedia</a>
                </p>
                <span className="divider" tabindex="-1"></span>
                <p className="header_3 span-header-2">
                  <a href="/licenciatura-marketing-digital">
                    Marketing Digital
                  </a>
                </p>
              </div>
            </li>
          </ul>
        </li>
        <li className="header_3">
          <button
            onClick={() => {
              redireccion("/cursos-diplomados");
            }}
          >
            Cursos y diplomados
          </button>
        </li>
        <li className="header_3">
          <a href="/beca-caav">Becas</a>
        </li>
        <li className="header_3">
          <a href="/caav-blogs">Blog</a>
        </li>
        <li className="header_3">
          <a href="/igualdad">Igualdad</a>
        </li>
        <li className="header_3">
          <a href="/entorno ">Entorno</a>
        </li>
        <li className="header_3">
          <a href="/elcaavsemueve ">Nueva sede</a>
        </li>
        <li>
          <a href="/proceso-admision">
            <span
              style={{
                color: "#C80A71",
                margin: "0",
                border: "solid 1px #C80A71",
                padding: "10px",
                borderRadius: "10px",
              }}
            >
              Cómo inscribirte
            </span>
          </a>
        </li>
        <li>
          <ul className="collapsible">
            <li>
              <div
                className="collapsible-header header_3"
                style={{ padding: "0px 32px", color: "#000000de" }}
              >
                Estudiantes
              </div>
              <div className="collapsible-body red_caav">
                <p className="header_3">
                  <a href="/reinscripcion">Cómo reinscribirte</a>
                </p>
                <span className="divider" tabindex="-1"></span>
                <p className="header_3">
                  <a href="/calendario">Calendario</a>
                </p>
                <span className="divider" tabindex="-1"></span>
                <p className="header_3">
                  <a href="/horario">Horarios</a>
                </p>
                <span className="divider" tabindex="-1"></span>
                <p className="header_3">
                  <a href="/derecho-examen">Derecho a examen</a>
                </p>
                <span className="divider" tabindex="-1"></span>
                <p className="header_3">
                  <a href="/horario-examen">Horarios de exámenes</a>
                </p>
                <span className="divider" tabindex="-1"></span>
                <p className="header_3">
                  <a href="/calificaciones">Calificaciones</a>
                </p>
                <span className="divider" tabindex="-1"></span>
                <p className="header_3">
                  <a href="/titulaciones">Titulación</a>
                </p>
              </div>
            </li>
          </ul>
        </li>
      </ul>

      {/*vinculos con hover*/}
      <ul id="dropdown1" className="dropdown-content hover">
        <li>
          <a href="/somos">Somos</a>
        </li>
        <li className="divider" tabindex="-1"></li>
        <li>
          <a href="/contacto">Contacto</a>
        </li>
        <li className="divider" tabindex="-1"></li>
        <li>
          <a href="/docentes">Docentes</a>
        </li>
      </ul>

      <ul id="dropdown2" className="dropdown-content hover">
        <li>
          <a href="/licenciatura-en-cine">Cine Digital</a>
        </li>
        <li className="divider" tabindex="-1"></li>
        <li>
          <a href="/licenciatura-en-animacion">Animación</a>
        </li>
        <li className="divider" tabindex="-1"></li>
        <li>
          <a href="/multimedia">Multimedia</a>
        </li>
        <li className="divider" tabindex="-1"></li>
        <li className="span-header">
          <a href="/licenciatura-marketing-digital">Marketing Digital</a>
        </li>
      </ul>

      <ul id="dropdown3" className="dropdown-content hover">
        <li>
          <a href="/reinscripcion">Cómo reinscribirte</a>
        </li>
        <li className="divider" tabindex="-1"></li>
        <li>
          <a href="/calendario">Calendario</a>
        </li>
        <li className="divider" tabindex="-1"></li>
        <li>
          <a href="/horario">Horarios</a>
        </li>
        <li className="divider" tabindex="-1"></li>
        <li>
          <a href="/derecho-examen">Derecho a examen</a>
        </li>
        <li className="divider" tabindex="-1"></li>
        <li>
          <a href="/horario-examen">Horarios de exámenes</a>
        </li>
        <li className="divider" tabindex="-1"></li>
        <li>
          <a href="/calificaciones">Calificaciones</a>
        </li>
        <li className="divider" tabindex="-1"></li>
        <li>
          <a href="/titulaciones">Titulación</a>
        </li>
      </ul>
    </Fragment>
  );
};

export default Header;
