import React from "react";
import Downloader from "js-file-downloader";
import Swal from "sweetalert2";

import FormReinscripcion from "../../Components/ReinscripcionComponents/FormReinscripcion/FormReinscripcion";
import CarruselPasos from "../../Components/ReinscripcionComponents/CarruselPasos/CarruselPasos";
import CarruselPagos from "../../Components/ReinscripcionComponents/CarruselPagos/CarruselPagos";

import img1 from "../../Images/escritorio/Reinscripciones/1.png";
import movil1 from "../../Images/movil/Reinscripcion/1.png";

import pdfHorarioEspecial from "../../Docs/horario_especial.pdf";

import "./Reinscripcion.css";

const Reinscripcion = () => {
  const data = {
    primeraFecha: "10 de diciembre",
    primerPrecio: "2,750",
    segundoPrecio: "4,000",
    segundaFecha: "5",
    tercerPrecio: "5,500",
    cuartoPrecio: "6,050",
    quintoPrecio: "22,000",
    sextoPrecio: "18,800",
    terceraFecha: "23 de enero de 2025.",
    septimoPrecio: "2,600",
    octavoPrecio: "3,700",
    novenoPrecio: "5,200",
    decimoPrecio: "5,720",
    onceavo: "20,800",
    doceavo: "17,800",
    cuartaFecha: "lunes 16 de diciembre a las 7 pm",
  };

  const download = () => {
    new Downloader({
      url: pdfHorarioEspecial,
    })
      .then(function () {
        // console.log("descargando");
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "Error al descargar archivo", "error");
      });
  };

  return (
    <>
      {window.screen.width > 550 ? (
        <img
          alt="Universidad de medios audiovisuales"
          src={img1}
          style={{ width: "100%" }}
        />
      ) : (
        <img
          alt="Universidad de medios audiovisuales"
          src={movil1}
          style={{ width: "100%" }}
        />
      )}

      <section
        style={{ margin: "20px auto 30px" }}
        className="center container contenedor_movil_chico_re"
      >
        <h1
          className="titulo_1_nv"
          style={{ marginBottom: "0", marginTop: "0" }}
        >
          Cómo reinscribirte
        </h1>
        <p className="titulo_4_nv" style={{ fontFamily: "Montserrat-Bold" }}>
          ¡Estás un paso <br className="invisible_escritorio" />
          más cerca de tu meta!
        </p>

        <div style={{ marginBottom: "0" }} className="row">
          <div className="col s12 m10 l10 offset-m1 offset-l1 center rein-fondo-1">
            <p
              className="titulo_4_nv"
              style={{ fontFamily: "Montserrat-Bold", color: "#ffffff" }}
            >
              ¡Reinscribete antes <br className="invisible_escritorio" />
              del {data.primeraFecha}!
            </p>
          </div>
          <div className="col s12 m10 l10 offset-m1 offset-l1 center rein-fondo-2">
            <p className="titulo_2_nv" style={{ color: "#ffffff" }}>
              Así pagas menos, aseguras tu lugar,
              <br /> quedas en tu mismo grupo,
              <br className="invisible_escritorio" /> y no acumulas faltas.
            </p>
          </div>

          <h2
            className="titulo_4_nv col s12"
            style={{ fontFamily: "Montserrat-Bold" }}
          >
            Reinscripción
          </h2>
          <div
            className="col s12 m10 l10 offset-m1 offset-l1 center"
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0",
            }}
          >
            <div className="col s12 m6 l6 center rein-fondo-3">
              <div>
                <p className="titulo_4_nv">
                  Hasta el <br className="invisible_escritorio" />
                  {data.primeraFecha} <br />
                  <span style={{ fontFamily: "Montserrat-Bold" }}>
                    ${data.primerPrecio} MXN
                  </span>
                </p>
              </div>
            </div>
            <div className="col s12 m6 l6 center rein-fondo-3">
              <div>
                <p className="titulo_4_nv">
                  Después del <br className="invisible_escritorio" />
                  {data.primeraFecha} <br />
                  <span style={{ fontFamily: "Montserrat-Bold" }}>
                    ${data.segundoPrecio} MXN
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="rein-fondo-4 center contenedro-carrusle-1 ">
        <h2
          className="titulo_4_nv"
          style={{ fontFamily: "Montserrat-Bold", marginTop: "0" }}
        >
          Pasos para reinscribirte:
        </h2>

        <CarruselPasos />
      </section>

      <section id="banco" className="rein-fondo-5 contenedro-carrusle-1">
        <h2
          className="titulo_4_nv"
          style={{
            fontFamily: "Montserrat-Bold",
            marginTop: "0",
            textAlign: "center",
          }}
        >
          Cómo y dónde pagar:
        </h2>
        <CarruselPagos />
      </section>

      <section
        style={{ marginTop: "20px" }}
        className="center container contenedor_movil_chico_re"
      >
        <h2
          className="titulo_4_nv"
          style={{
            fontFamily: "Montserrat-Bold",
            marginTop: "0",
            textAlign: "center",
          }}
        >
          Precios <br className="invisible_escritorio" />
          cuatrimestrales
        </h2>
        <h2
          className="titulo_4_nv"
          style={{
            fontFamily: "Montserrat-Bold",
            color: "#DE2657",
            textAlign: "center",
            marginTop: "0",
          }}
        >
          Cine Digital, Animación <br className="invisible_escritorio" />y
          Multimedia
        </h2>

        <div className="row">
          <div
            className="col s12 m10 l10 offset-m1 offset-l1 center"
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0",
            }}
          >
            <div className="col s12 m6 l6 center rein-fondo-7">
              <div>
                <p
                  className="titulo_4_nv"
                  style={{
                    fontFamily: "Montserrat-Bold",
                    color: "#DE2657",
                    marginBottom: "0",
                  }}
                >
                  Reinscripción
                </p>
                <p style={{ marginBottom: "0" }}>
                  Hasta el {data.primeraFecha} <br />
                  <span
                    className="titulo_4_nv"
                    style={{ fontFamily: "Montserrat-Bold" }}
                  >
                    ${data.primerPrecio} MXN
                  </span>
                </p>
                <p>
                  Después del <br className="invisible_escritorio" />
                  {data.primeraFecha} <br />
                  <span
                    className="titulo_4_nv"
                    style={{ fontFamily: "Montserrat-Bold" }}
                  >
                    ${data.segundoPrecio} MXN
                  </span>
                </p>
              </div>
            </div>
            <div className="col s12 m6 l6 center rein-fondo-7">
              <div>
                <p
                  className="titulo_4_nv"
                  style={{
                    fontFamily: "Montserrat-Bold",
                    color: "#DE2657",
                    marginBottom: "0",
                  }}
                >
                  4 colegiaturas
                </p>
                <p style={{ marginBottom: "0" }}>
                  Antes del día {data.segundaFecha}{" "}
                  <br className="invisible_escritorio" />
                  de cada mes
                  <br />
                  <span
                    className="titulo_4_nv"
                    style={{ fontFamily: "Montserrat-Bold" }}
                  >
                    ${data.tercerPrecio} MXN
                  </span>
                </p>
                <p>
                  Después del día {data.segundaFecha}{" "}
                  <br className="invisible_escritorio" />
                  de cada mes
                  <br />
                  <span
                    className="titulo_4_nv"
                    style={{ fontFamily: "Montserrat-Bold" }}
                  >
                    ${data.cuartoPrecio} MXN
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div
            style={{ marginTop: "15px" }}
            className="col s12 m10 l10 offset-m1 offset-l1 center rein-fondo-8"
          >
            <div>
              <p
                className="titulo_4_nv"
                style={{
                  fontFamily: "Montserrat-Bold",
                  color: "#ffffff",
                  marginBottom: "0",
                }}
              >
                Si cubres las cuatro colegiaturas <br />
                de contado, en lugar de ${data.quintoPrecio} MXN, <br />
                pagas sólo ${data.sextoPrecio} MXN,
              </p>
              <p style={{ color: "#ffffff" }}>
                liquidando antes antes del
                <br className="invisible_escritorio" />{" "}
                <b>{data.terceraFecha}</b> <br />
                Además de tu inscripción cuatrimestral.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section
        style={{ marginBottom: "20px" }}
        className="center container contenedor_movil_chico_re"
      >
        <h2
          className="titulo_4_nv"
          style={{
            fontFamily: "Montserrat-Bold",
            color: "#164C95",
            textAlign: "center",
            marginTop: "0",
          }}
        >
          Marketing Digital Creativo
        </h2>

        <div style={{ marginBottom: "0" }} className="row">
          <div
            className="col s12 m10 l10 offset-m1 offset-l1 center"
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0",
            }}
          >
            <div className="col s12 m6 l6 center rein-fondo-7">
              <div>
                <p
                  className="titulo_4_nv"
                  style={{
                    fontFamily: "Montserrat-Bold",
                    color: "#02B5C1",
                    marginBottom: "0",
                  }}
                >
                  Reinscripción
                </p>
                <p style={{ marginBottom: "0" }}>
                  Hasta el {data.primeraFecha} <br />
                  <span
                    className="titulo_4_nv"
                    style={{ fontFamily: "Montserrat-Bold" }}
                  >
                    ${data.septimoPrecio} MXN
                  </span>
                </p>
                <p>
                  Después del <br className="invisible_escritorio" />
                  {data.primeraFecha} <br />
                  <span
                    className="titulo_4_nv"
                    style={{ fontFamily: "Montserrat-Bold" }}
                  >
                    ${data.octavoPrecio} MXN
                  </span>
                </p>
              </div>
            </div>
            <div className="col s12 m6 l6 center rein-fondo-7">
              <div>
                <p
                  className="titulo_4_nv"
                  style={{
                    fontFamily: "Montserrat-Bold",
                    color: "#02B5C1",
                    marginBottom: "0",
                  }}
                >
                  4 colegiaturas
                </p>
                <p style={{ marginBottom: "0" }}>
                  Antes del día {data.segundaFecha}{" "}
                  <br className="invisible_escritorio" />
                  de cada mes
                  <br />
                  <span
                    className="titulo_4_nv"
                    style={{ fontFamily: "Montserrat-Bold" }}
                  >
                    ${data.novenoPrecio} MXN
                  </span>
                </p>
                <p>
                  Después del día {data.segundaFecha}{" "}
                  <br className="invisible_escritorio" />
                  de cada mes
                  <br />
                  <span
                    className="titulo_4_nv"
                    style={{ fontFamily: "Montserrat-Bold" }}
                  >
                    ${data.decimoPrecio} MXN
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div className="col s12 m10 l10 offset-m1 offset-l1 center rein-fondo-9">
            <div>
              <p
                className="titulo_4_nv"
                style={{
                  fontFamily: "Montserrat-Bold",
                  color: "#ffffff",
                  marginBottom: "0",
                }}
              >
                Si cubres las cuatro colegiaturas <br />
                de contado, en lugar de ${data.onceavo} MXN, <br />
                pagas sólo ${data.doceavo} MXN,
              </p>
              <p style={{ color: "#ffffff" }}>
                liquidando antes antes del{" "}
                <br className="invisible_escritorio" />
                <b>{data.terceraFecha}</b> <br />
                Además de tu inscripción cuatrimestral.
              </p>
            </div>
          </div>

          <div
            style={{ background: "#B52C93" }}
            className="col s12 m10 l10 offset-m1 offset-l1 center rein-fondo-9"
          >
            <p
              style={{
                color: "#ffffff",
                fontWeight: "bold",
                fontFamily: "Montserrat-Bold",
              }}
            >
              Costo de cada examen extraordinario: $1,500 MXN
            </p>
          </div>
        </div>
      </section>

      <section style={{ background: "#F0F2F6", padding: "20px 0" }}>
        <div
          className="center container contenedor_movil_chico_re"
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h2
            className="titulo_4_nv"
            style={{
              fontFamily: "Montserrat-Bold",
              color: "#164C95",
              textAlign: "center",
              margin: "0",
            }}
          >
            Si eres estudiante de reingreso
          </h2>
          {/* TODO: integrar estas fechas de forma dinamica */}
          <p>
            Es decir, si te diste de baja del CAAV <br />
            <b>y te reinscribes antes del 13 de agosto,</b> <br />
            recibirás un correo de Coordinación Académica <br />
            <b>a más tardar el 22 de agosto,</b> <br />
            en el que te confirmaremos si hay lugar{" "}
            <br className="invisible_escritorio" /> disponible en tu grupo.
          </p>
          <a
            className="boton-rein-nv"
            style={{ background: "#B52C93" }}
            href="mailto:jacqueline@caav.mx"
            target="_blanck"
          >
            Envía un correo aquí
          </a>
        </div>
      </section>

      <section style={{ background: "#ffffff", padding: "20px 0" }}>
        <div
          className="center container contenedor_movil_chico_re"
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h2
            className="titulo_4_nv"
            style={{
              fontFamily: "Montserrat-Bold",
              color: "#164C95",
              textAlign: "center",
              margin: "0",
            }}
          >
            Horarios especiales
          </h2>
          <p style={{ marginBottom: "0" }}>
            Si tienes materias por repetir o por cursar, <br />
            <b>
              debes hacer un horario especial
              <br className="invisible_escritorio" /> para tomar esas
              asignaturas.
            </b>
            <br />
            <b style={{ color: "#DE2657" }}>
              *Es importante que sepas que éstas
              <br className="invisible_escritorio" /> no se pueden posponer.
              <br />
              <br className="invisible_escritorio" />
              Sólo así estarás en listas
              <br className="invisible_escritorio" /> y no acumularás faltas.
            </b>
            <br />
            <br />
            Se hace en la oficina de Coordinación Académica,
            <br className="invisible_escritorio" /> con Sofy Jiménez.
          </p>
        </div>
      </section>

      <section style={{ background: "#F0F2F6", padding: "20px 0" }}>
        <div
          className="center container contenedor_movil_chico_re"
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h2
            className="titulo_4_nv"
            style={{
              fontFamily: "Montserrat-Bold",
              color: "#164C95",
              textAlign: "center",
              margin: "0",
            }}
          >
            Si quieres dar de alta <br className="invisible_escritorio" />
            una materia optativa
          </h2>
          <p style={{ marginBottom: "0" }}>
            (Son materias de las otras licenciaturas
            <br className="invisible_escritorio" /> que puedes cursar a partir
            de 5º cuatrimestre)
            <br />
            <br />
            <b style={{ color: "#DE2657" }}>
              Sólo puedes dar de alta una materia optativa
              <br className="invisible_escritorio" /> en la primera semana de
              clases,
            </b>
            <br />
            en la oficina de Coordinación Académica,
            <br className="invisible_escritorio" /> con Sofy Jiménez.
          </p>
        </div>
      </section>

      <section
        style={{ background: "#ffffff", padding: "20px 0" }}
        className="center container contenedor_movil_chico_re"
      >
        <h2
          className="titulo_4_nv"
          style={{
            fontFamily: "Montserrat-Bold",
            textAlign: "center",
            marginTop: "0",
          }}
        >
          Pasos para hacer <br className="invisible_escritorio" />
          tu horario especial <br className="invisible_escritorio" />
          en persona:
        </h2>

        <div className="row" style={{ marginBottom: "0" }}>
          <div
            className="col s12 m10 l10 offset-m1 offset-l1 center"
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0",
            }}
          >
            <div className="col s12 m6 l6 center rein-fondo-10">
              <div>
                <p
                  className="titulo_4_nv"
                  style={{
                    fontFamily: "Montserrat-Bold",
                    color: "#02B5C1",
                    margin: "0",
                  }}
                >
                  1 <br className="invisible_escritorio" />
                  Reinscríbete
                </p>
                <p style={{ marginBottom: "0" }}>
                  antes del <br className="invisible_escritorio" />
                  {data.primeraFecha}
                </p>
              </div>
            </div>
            <div className="col s12 m6 l6 center rein-fondo-10">
              <div>
                <p
                  className="titulo_4_nv"
                  style={{
                    fontFamily: "Montserrat-Bold",
                    color: "#02B5C1",
                    margin: "0",
                  }}
                >
                  2 <br className="invisible_escritorio" />
                  Acude a hacer <br className="invisible_escritorio" />
                  tu horario
                </p>
                <p style={{ marginBottom: "0" }}>
                  a la oficina de Coordinación{" "}
                  <br className="invisible_escritorio" />
                  Académica <br />
                  con tu ficha de reinscripción.
                </p>
              </div>
            </div>
          </div>

          <div
            className="col s12 m10 l10 offset-m1 offset-l1 center"
            style={{ padding: "0px", marginTop: "15px" }}
          >
            <div className="rein-fondo-11">
              <div style={{ height: "100%" }}>
                <p
                  className="titulo_4_nv"
                  style={{
                    fontFamily: "Montserrat-Bold",
                    color: "#02B5C1",
                    margin: "0",
                  }}
                >
                  Horarios de atención:
                </p>
                {/* TODO: integrar estas fechas de forma dinamica */}
                <p style={{ marginBottom: "0" }}>
                  <b>
                    Lunes 16, martes 17 de diciembre de 2024, lunes 6{" "}
                    <br className="invisible_escritorio" /> y martes 7 de enero
                    de 2025, de 10 am a 2 pm.
                  </b>
                  <br />
                  ¡Haz tu horario en las fechas indicadas,
                  <br className="invisible_escritorio" /> para asegurar tu
                  lugar,
                  <br />
                  pagar menos y no acumular faltas!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section style={{ background: "#F0F2F6", padding: "20px 0" }}>
        <div className="center container contenedor_movil_chico_re">
          <h2
            className="titulo_4_nv"
            style={{
              fontFamily: "Montserrat-Bold",
              textAlign: "center",
              marginTop: "0",
            }}
          >
            Pasos para hacer <br className="invisible_escritorio" />
            tu horario especial <br className="invisible_escritorio" />
            en línea:
          </h2>

          <div className="row" style={{ marginBottom: "0" }}>
            <div
              className="col s12 m10 l10 offset-m1 offset-l1 center"
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0",
                flexWrap: "wrap",
              }}
            >
              <div className="col s12 m6 l6 center rein-fondo-10-1">
                <div>
                  <p
                    className="titulo_4_nv"
                    style={{
                      fontFamily: "Montserrat-Bold",
                      color: "#02B5C1",
                      margin: "0",
                    }}
                  >
                    1 <br className="invisible_escritorio" />
                    Reinscríbete
                  </p>
                  <p className="margin-top-0" style={{ marginBottom: "0" }}>
                    antes del {data.primeraFecha}
                  </p>
                </div>
              </div>
              <div className="col s12 m6 l6 center rein-fondo-10-1 margtin-top-1">
                <div>
                  <p
                    className="titulo_4_nv"
                    style={{
                      fontFamily: "Montserrat-Bold",
                      color: "#02B5C1",
                      margin: "0",
                    }}
                  >
                    2 <br className="invisible_escritorio" />
                    Pide tu cita virtual
                  </p>
                  <p style={{ marginBottom: "0" }}>
                    ¡antes del <b>{data.cuartaFecha}!</b>
                    <br />
                    <br />
                    Escribe un correo a{" "}
                    <b>
                      <a
                        style={{
                          color: "#B52C93",
                          textDecoration: "underline",
                        }}
                        href="mailto:academia@caav.mx"
                        target="_blanck"
                      >
                        academia@caav.mx
                      </a>
                    </b>
                    , <br />
                    con copia a{" "}
                    <b>
                      <a
                        style={{
                          color: "#B52C93",
                          textDecoration: "underline",
                        }}
                        href="mailto:jacqueline@caav.mx"
                        target="_blanck"
                      >
                        jacqueline@caav.mx
                      </a>
                    </b>
                  </p>
                </div>
              </div>
            </div>

            <div
              className="col s12 m10 l10 offset-m1 offset-l1 center margen-rein-1"
              style={{ padding: "0px" }}
            >
              <div className="rein-fondo-11">
                <div style={{ height: "100%" }}>
                  <p
                    className="titulo_4_nv"
                    style={{
                      fontFamily: "Montserrat-Bold",
                      color: "#02B5C1",
                      margin: "0",
                    }}
                  >
                    Horarios de atención:
                  </p>
                  {/* TODO: integrar estas fechas de forma dinamica */}
                  <p style={{ marginBottom: "0" }}>
                    <b>Miércoles 18 de diciembre de 10 am a 12 pm.</b> <br />
                    ¡Haz tu horario en las fechas indicadas,
                    <br className="invisible_escritorio" /> para asegurar tu
                    lugar, <br />
                    pagar menos y no acumular faltas!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        style={{ background: "#ffffff", padding: "20px 0" }}
        className="container contenedor_movil_chico_re"
      >
        <div className="row" style={{ marginBottom: "0" }}>
          <div
            className="col s12 m10 l10 offset-m1 offset-l1 center"
            style={{
              background: "#02B5C1",
              borderRadius: "18px",
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {/* TODO: integrar estas fechas de forma dinamica */}
            <p
              className="titulo_4_nv"
              style={{
                fontFamily: "Montserrat-Bold",
                color: "#ffffff",
                margin: "0",
              }}
            >
              Costo de materia a repetir:
            </p>
            <p style={{ color: "#ffffff", marginBottom: "0" }}>
              Si haces tu horario especial{" "}
              <br className="invisible_escritorio" />
              en las fechas señaladas arriba <b>$3,000 MXN</b>
              <br />
              <br />
              <b>Después de esta fecha, el precio sube:</b>
              <br />
              <br />
              <b>
                Lunes 13 y martes 14 de enero,{" "}
                <br className="invisible_escritorio" />
                de 10 am a 2 pm, $3,500 MXN
              </b>
              <br />
              Si no repites asignaturas y sólo tienes materias pendientes por
              cursar,
              <br />
              se te hará un cargo de <b>$500 MXN</b>{" "}
              <br className="invisible_escritorio" />
              por hacer el horario fuera de tiempo.
              <br />
              <br />
              <br />
              <b>
                Lunes 20 y martes 21 de enero,{" "}
                <br className="invisible_escritorio" />
                de 10 am a 2 pm, $4,000 MXN
              </b>
              <br />
              Si no repites asignaturas y sólo tienes materias pendientes por
              cursar,
              <br />
              se te hará un cargo de <b>$1,000 MXN</b>{" "}
              <br className="invisible_escritorio" />
              por hacer el horario fuera de tiempo.
              <br />
              <br />
              Si deseas más detalles, <br className="invisible_escritorio" />
              <b>descarga el PDF</b> con la información
            </p>
            <button
              className="boton-rein-nv"
              onClick={download}
              style={{
                color: "#02B5C1",
                background: "#ffffff",
                marginTop: "15px",
              }}
            >
              <b>Aquí</b>
            </button>
          </div>
        </div>
      </section>

      <section
        style={{ background: "#ffffff", padding: "0" }}
        id="formulario"
        className="container row contenedor_movil_chico_re"
      >
        <div className="col s12 m10 l10 offset-m1 offset-l1">
          <h2
            className="titulo_4_nv titulo-rein-1 center"
            style={{ marginTop: "0" }}
          >
            Formulario de reinscripción
          </h2>
          <p className="center">
            *Si deseas hacer cambio de licenciatura, <br />
            debes hacer el trámite en la oficina{" "}
            <br className="invisible_escritorio" />
            de Control Escolar.
          </p>

          <p
            className="titulo_4_nv titulo_4_rein margin-bottom"
            style={{
              fontFamily: "Montserrat-Bold",
              margin: "0",
            }}
          >
            <b>
              Quiero reinscribirme a la licenciatura{" "}
              <br className="invisible_escritorio" />
              que ya estoy cursando:
            </b>
          </p>
          <FormReinscripcion />
        </div>
      </section>
    </>
  );
};

export default Reinscripcion;
