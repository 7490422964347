import React, { Fragment, useEffect, useState } from "react";
// import Downloader from "js-file-downloader";
import Swal from "sweetalert2";

import folleto from "../../../Docs/folleto.pdf";

import "./Marketing.css";

import video from "../../../Images/escritorio/Licenciaturas/Marketing/video/video.m4v";
import imagenVideo from "../../../Images/escritorio/Licenciaturas/Marketing/video/1.png";

import img2 from "../../../Images/escritorio/Licenciaturas/Marketing/1.png";
import logo_2 from "../../../Images/logos/logo_2.svg";
import icono_2 from "../../../Images/Icon/4_2.png";
import icono_1 from "../../../Images/Icon/3_2.png";

import Loader from "../../../Components/Loader/Loader";
import licenciaturasHelpers from "../../../Helpers/Licenciaturas";
import Carrusel from "../../../Components/CarruselMarcketing/Carrusel";

const Marketing = () => {
  const [data, setData] = useState([]);
  const [flag, setFlag] = useState(false);
  const [condiciones, setCondiciones] = useState(false);

  useEffect(() => {
    // Swal.fire({
    //   html:
    //     "<p class='texto_pop'><span class='titulo_4_nv titulo_bold'>Ven al Open House</span></br>" +
    //     "este 22 de noviembre a las 3:30 pm.</p>",
    //   showCloseButton: true,
    //   showCancelButton: false,
    //   focusConfirm: false,
    //   confirmButtonText: '<i class="fa fa-whatsapp"></i> Reserva tu lugar aquí',
    //   confirmButtonColor: "#FF9700",
    // }).then((res) => {
    //   if (res.isConfirmed) {
    //     // redireccionamos
    //     var nuevaVentana = window.open();
    //     nuevaVentana.location.href =
    //       "https://api.whatsapp.com/send?phone=5213318958778";
    //   }
    // });

    obtenerLicenciatura();
  }, []);

  const obtenerLicenciatura = async () => {
    let res = await licenciaturasHelpers.obtenerUnaLicenciatura(
      "6404b9ca0bfddd714255ddf0"
    );

    if (res.success) {
      setData(res.data);
      setFlag(true);
    } else {
      Swal.fire(
        "Error!",
        "No se pudo obtener la licenciatura, codigo: " + res.code,
        "error"
      );
    }
  };

  function createMarkup(materias) {
    return { __html: materias };
  }

  // const download = () => {
  //   new Downloader({
  //     url: folleto,
  //   })
  //     .then(function () {
  //       // console.log("descargando");
  //     })
  //     .catch(function (error) {
  //       Swal.fire("Error", "Error al descargar archivo", "error");
  //     });
  // };

  // seguimiento de google
  const gtag_report_conversion_descragar = () => {
    // descargar folleto
    var callback = function () {
      if (typeof folleto != "undefined") {
        window.open(folleto, "_blank");
      }
    };
    window.gtag("event", "conversion", {
      send_to: "AW-1004461178/X1lcCMi9o5AYEPq4-94D",
      event_callback: callback,
    });
    return false;
  };

  const gtag_report_conversion = (url) => {
    // proceso de admision
    var callback = function () {
      if (typeof url != "undefined") {
        window.location.replace(url);
      }
    };
    window.gtag("event", "conversion", {
      send_to: "AW-1004461178/l1PzCL-9q5AYEPq4-94D",
      event_callback: callback,
    });
    return false;
  };

  return (
    <Fragment>
      {condiciones && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            className="contenedor_pop_curso"
            onClick={() => {
              setCondiciones(false);
            }}
          ></div>
          <div className="pop_curso">
            <div
              className="pop_salir"
              onClick={() => {
                setCondiciones(false);
              }}
            />
            <p
              className="titulo_pop"
              style={{ color: "#154C95", textAlign: "center", margin: "0" }}
            >
              ¿Qué validez tienen nuestras licenciaturas?
            </p>
            <p>
              Nuestra Universidad se encuentra incorporada a la Secretaría de
              Innovación, Ciencia y Tecnología de Jalisco (SICyT). En Jalisco,
              desde 2013, esta es la Secretaría que se encarga de dar validez a
              planes y programas de estudios de nivel superior, lo que en otros
              Estados hace la Secretaría de Educación. <br />
              <br />
              Nuestras licenciaturas cuentan con el Reconocimiento de Validez
              Oficial de Estudio RVOE, y Cedula Federal, los cuales puedes
              consultar en el siguiente Link:{" "}
              <a href="https://sicyt.jalisco.gob.mx/educacion-superior/directorio-de-instituciones-con-rvoe">
                https://sicyt.jalisco.gob.mx/educacion-superior/directorio-de-instituciones-con-rvoe
              </a>{" "}
              donde nos puedes buscar con el nombre de fundación de la
              Institución: Centro de Arte Audiovisual.
            </p>
          </div>
        </div>
      )}
      <video
        style={{ width: "100%" }}
        id="video"
        autoPlay
        muted
        loop
        poster={imagenVideo}
      >
        <source src={video} type="video/mp4" />
      </video>
      <div className="container center" style={{ marginBottom: "20px" }}>
        <h1 className="encabezadosCaav" style={{ marginTop: "15px" }}>
          <span className="encabezadosMonserrat">Licenciatura en</span>
          <br />
          Marketing <br />
          <span className="encabezadosMonserrat">Digital Creativo</span>
        </h1>
        <p className="encabezadosMonserrat">
          <b>Ingreso anual, en septiembre.</b>
        </p>
        <img src={logo_2} className="logo_yo_creo" alt="#yoCreo CAAV" />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <p className="titulo_frase">
            Yo creo en el poder de la comunicación. <br />
            Yo creo en decir la verdad. <br />
            Yo creo historias que convencen y enamoran.
          </p>
        </div>
        {!flag ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
              width: "100%",
            }}
          >
            <Loader />
          </div>
        ) : (
          <Carrusel />
        )}
      </div>

      <div
        style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}
      >
        <div className="caja_5" style={{ background: "#F2F2F2" }}>
          <div className="texto_licenciatura_carrera">
            <p className="titulo_1_nv" style={{ margin: "0px" }}>
              La carrera
            </p>
            <p>
              Esta licenciatura está dirigida a las personas interesadas en
              desarrollar increíbles contenidos audiovisuales y estrategias de
              marketing para fines publicitarios en las redes sociales.
            </p>
            <p>
              Estudiar Marketing Digital Creativo te dará las habilidades para
              crear eficientes estrategias de marketing en Internet y para
              generar mensajes que cautiven al público y den resultados a tus
              clientes. El sustento de tu trabajo -análisis, estrategia,
              planeación y creatividad, te ayudará a sobresalir entre tus
              colegas.
            </p>
            <p>
              Vas a saber estudiar y evaluar a los diferentes consumidores
              digitales, desarrollar estrategias de mercado y de comunicación,
              planificar, organizar e implementar proyectos creativos y producir
              contenido audiovisual de alta calidad para tus campañas.
            </p>
            <p>
              Es decir, vas a ser una persona experta en el marketing, la
              comunicación persuasiva, la publicidad y la comercialización a
              través de medios digitales.
            </p>
          </div>
          <div
            className="texto_licenciatura_carrera"
            style={{ display: "block" }}
          >
            <p className="titulo_1_nv" style={{ margin: "0px" }}>
              Perfil de ingreso
            </p>
            <p style={{ fontWeight: "bold" }}>Intereses:</p>
            <div className="texto_lic_9 puntos_1_nv">
              <ul>
                <li>Las redes sociales.</li>
                <li>La mercadotecnia.</li>
                <li>La publicidad y la comunicación.</li>
                <li>La fotografía, el video y demás medios audiovisuales.</li>
                <li>El emprendimiento de proyectos.</li>
                <li>Las marcas y las empresas.</li>
                <li>Las artes plásticas.</li>
                <li>La música.</li>
              </ul>
            </div>
            <p style={{ fontWeight: "bold" }}>Habilidades y destrezas:</p>
            <div className="texto_lic_9 puntos_1_nv">
              <ul>
                <li>Pensamiento analítico, crítico, estratégico.</li>
                <li>Creatividad.</li>
                <li>Originalidad.</li>
                <li>Capacidad de análisis.</li>
                <li>Capacidad de síntesis.</li>
                <li>Atención al detalle.</li>
                <li>Capacidad para administrar el tiempo.</li>
                <li>Intención de colaboración y de trabajar en equipo.</li>
                <li>Aptitud para las relaciones públicas.</li>
                <li>Capacidad para tomar decisiones.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="container contenedor_chico" style={{ marginTop: "15px" }}>
        <div className="center">
          <p className="titulo_4_nv">Tu campo de trabajo</p>
          <p>
            En un mundo que cada vez consume más y más a través de los medios
            digitales, tu campo de trabajo se irá haciendo cada vez más vasto.{" "}
            <br />
            Nosotros te daremos las herramientas y el conocimiento para vender
            lo que tú quieras.
          </p>
          <p>Podrás trabajar, por ejemplo, como:</p>
        </div>
        <div style={{ marginLeft: "30px" }}>
          <ul className="puntos">
            <li>Copywriter.</li>
            <li>Estratega digital.</li>
            <li>Creativo publicitario.</li>
            <li>Influencer marketing.</li>
            <li>Director de mercadotecnia digital.</li>
            <li>Director de proyectos creativos o de contenidos.</li>
            <li>Realizador y productor audiovisual para publicidad.</li>
            <li>Community manager o social media manager (gestor de redes).</li>
            <li>
              Coordinador de inteligencia y gestión de relación con el cliente.
            </li>
          </ul>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <a
          onClick={() => {
            gtag_report_conversion("https://caav.mx/proceso-admision");
          }}
          href="/proceso-admision"
        >
          <div className="boton_licenciatura_4" style={{ marginTop: "0px" }}>
            Conoce el proceso de admisión
          </div>
        </a>
      </div>

      {!flag ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
            width: "100%",
          }}
        >
          <Loader />
        </div>
      ) : (
        <div
          className="center contenedor_d3"
          style={{ background: "#154C95", color: "#fff" }}
        >
          <p
            className="titulo_5_nv"
            style={{ margin: "0px", fontWeight: "bold" }}
          >
            Inicio de clases:
          </p>
          <p className="titulo_fecha_nv" style={{ margin: "0px" }}>
            {data.inicio}
          </p>
          <p
            className="titulo_5_nv"
            style={{ fontWeight: "bold", marginBottom: "0px" }}
          >
            Esta licenciatura tiene plan cuatrimestral
            <br />e ingreso anual, en septiembre.
          </p>
        </div>
      )}

      <div
        className="contenedor_2_d3 flex_padre_cine"
        style={{ background: "#E4E4E4" }}
      >
        <div
          className="flex_1"
          style={{
            display: "flex",
            // justifyContent:"center",
            alignItems: "center",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <img
            src={icono_1}
            alt=""
            className="imagen_lic"
            style={{ fontSize: "14px" }}
          />
          <p className="titulo_4_nv" style={{ marginBottom: "0px" }}>
            ¿Qué validez tienen <br />
            nuestras licenciaturas?
          </p>
          <p style={{ margin: "0" }}>ESLI14202424</p>
          <button
            style={{
              color: "#000",
              border: "none",
              background: "transparent",
              fontWeight: "bold",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => {
              setCondiciones(true);
            }}
          >
            Ver detalles.
          </button>
        </div>
        <div
          className="flex_1"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <img
            src={icono_2}
            alt=""
            className="imagen_lic"
            style={{ fontSize: "14px" }}
          />
          <p className="titulo_4_nv" style={{ marginBottom: "0px" }}>
            Horario
          </p>
          <p style={{ marginTop: "0px" }}>
            De lunes a viernes, de 8:00 am a 3:00 pm. <br />
            Pueden programarse algunas sesiones el sábado.
          </p>
        </div>
      </div>

      <div
        className="row"
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "stretch",
          marginBottom: "0px",
        }}
      >
        <div className="col s12 m12 l5" style={{ padding: "0px" }}>
          <div style={{ height: "100%" }}>
            <img
              src={img2}
              className="imagen_licenciatura"
              alt="- Licenciatura en Marketing"
            />
          </div>
        </div>
        <div className="col s12 m12 l7" style={{ background: "#fff" }}>
          <p
            className="encabezadosCaav contenedor_lic_plan"
            style={{ marginTop: "20px", marginBottom: "0px" }}
          >
            Esto es lo que aprenderás <br />
            <span className="encabezadosMonserrat">
              Plan de estudios, en nueve cuatrimestres
            </span>
          </p>
          {!flag ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
                width: "100%",
              }}
            >
              <Loader />
            </div>
          ) : (
            <div className="flex_2_d3 contenedor_lic_plan">
              <div className="hijo_1_d3">
                <p className="numero_d3_m" style={{ marginBottom: "-10px" }}>
                  1.
                </p>
                <div
                  dangerouslySetInnerHTML={createMarkup(data.semestre[0].data)}
                  className="texto_lic_8"
                />
              </div>
              <div className="hijo_1_d3">
                <p className="numero_d3_m" style={{ marginBottom: "-10px" }}>
                  2.
                </p>
                <div
                  dangerouslySetInnerHTML={createMarkup(data.semestre[1].data)}
                  className="texto_lic_8"
                />
              </div>
              <div className="hijo_1_d3">
                <p className="numero_d3_m" style={{ marginBottom: "-10px" }}>
                  3.
                </p>
                <div
                  dangerouslySetInnerHTML={createMarkup(data.semestre[2].data)}
                  className="texto_lic_8"
                />
              </div>
              <div className="hijo_1_d3">
                <p className="numero_d3_m" style={{ marginBottom: "-10px" }}>
                  4.
                </p>
                <div
                  dangerouslySetInnerHTML={createMarkup(data.semestre[3].data)}
                  className="texto_lic_8"
                />
              </div>
              <div className="hijo_1_d3">
                <p className="numero_d3_m" style={{ marginBottom: "-10px" }}>
                  5.
                </p>
                <div
                  dangerouslySetInnerHTML={createMarkup(data.semestre[4].data)}
                  className="texto_lic_8"
                />
              </div>
              <div className="hijo_1_d3">
                <p className="numero_d3_m" style={{ marginBottom: "-10px" }}>
                  6.
                </p>
                <div
                  dangerouslySetInnerHTML={createMarkup(data.semestre[5].data)}
                  className="texto_lic_8"
                />
              </div>
              <div className="hijo_1_d3">
                <p className="numero_d3_m" style={{ marginBottom: "-10px" }}>
                  7.
                </p>
                <div
                  dangerouslySetInnerHTML={createMarkup(data.semestre[6].data)}
                  className="texto_lic_8"
                />
              </div>
              <div className="hijo_1_d3">
                <p className="numero_d3_m" style={{ marginBottom: "-10px" }}>
                  8.
                </p>
                <div
                  dangerouslySetInnerHTML={createMarkup(data.semestre[7].data)}
                  className="texto_lic_8"
                />
              </div>
              <div className="hijo_1_d3">
                <p className="numero_d3_m" style={{ marginBottom: "-10px" }}>
                  9.
                </p>
                <div
                  dangerouslySetInnerHTML={createMarkup(data.semestre[8].data)}
                  className="texto_lic_8"
                />
              </div>
              <div
                className="col s12"
                style={{ marginTop: "30px", padding: "0px" }}
              >
                <button
                  onClick={gtag_report_conversion_descragar}
                  className="boton_licenciatura_4"
                >
                  Descargar folleto
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default Marketing;
