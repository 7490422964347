import React from "react";

import "./Entorno.css";

import CarruselImagenes from "../../Components/CarruselImagenes/CarruselImagenes";
import imagenes from "../../Components/CarruselImagenes/Imagenes/ImagenesCarruselEntorno";

import img1 from "../../Images/escritorio/Entorno/1.png";
import movil1 from "../../Images/movil/Entorno/1.png";

import logo1 from "../../Images/escritorio/Entorno/logos/1.svg";
import logo2 from "../../Images/escritorio/Entorno/logos/2.svg";
import logo3 from "../../Images/escritorio/Entorno/logos/3.svg";
import logo4 from "../../Images/escritorio/Entorno/logos/4.png";
import logo5 from "../../Images/escritorio/Entorno/logos/5.png";
import logo6 from "../../Images/escritorio/Entorno/logos/6.png";
import logo7 from "../../Images/escritorio/Entorno/logos/7.png";
import logo8 from "../../Images/escritorio/Entorno/logos/8.png";
import logo9 from "../../Images/escritorio/Entorno/logos/9.png";
import logo10 from "../../Images/escritorio/Entorno/logos/10.png";
import logo11 from "../../Images/escritorio/Entorno/logos/11.png";
import logo12 from "../../Images/escritorio/Entorno/logos/12.png";
import logo13 from "../../Images/escritorio/Entorno/logos/13.png";
import logo14 from "../../Images/escritorio/Entorno/logos/14.png";
import logo15 from "../../Images/escritorio/Entorno/logos/15.png";
import logo16 from "../../Images/escritorio/Entorno/logos/16.png";
import logo17 from "../../Images/escritorio/Entorno/logos/17.png";
import logo18 from "../../Images/escritorio/Entorno/logos/18.png";
import logo19 from "../../Images/escritorio/Entorno/logos/19.png";
import logo20 from "../../Images/escritorio/Entorno/logos/20.png";
import logo21 from "../../Images/escritorio/Entorno/logos/21.png";
import logo22 from "../../Images/escritorio/Entorno/logos/22.png";
import logo24 from "../../Images/escritorio/Entorno/logos/24.png";
import logo25 from "../../Images/escritorio/Entorno/logos/25.png";

const Entorno = () => {
  return (
    <div>
      {window.screen.width > 550 ? (
        <img src={img1} style={{ width: "100%" }} alt="Entorno caav" />
      ) : (
        <img src={movil1} style={{ width: "100%" }} alt="Entorno caav" />
      )}

      <div className="container center contenedor_chico_entorno">
        <p
          className="encabezadosCaav"
          style={{ marginTop: "15px", marginBottom: "0px" }}
        >
          <span className="encabezadosMonserrat">Estamos en el</span>
          <br />
          Corazón de Guadalajara
        </p>
        <p>
          Nuestras sedes están en la <b>Colonia Americana</b>, <br />
          corazón cultural y gastronómico de la zona metropolitana de <br />
          Guadalajara, que además, fue declarado el{" "}
          <b>
            barrio más cool del <br />
            mundo
          </b>
          , según el ranking mundial del Time Out Index 2022.
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="boton_0_nv" style={{ margin: "0px", width: "auto" }}>
            <a
              target="blank"
              href="https://www.timeoutmexico.mx/ciudad-de-mexico/que-hacer/el-mejor-barrio-del-mundo-2022-guadalajara-index"
            >
              <p>Aquí puedes ver el artículo</p>
            </a>
          </div>
        </div>
        <p>
          Inscríbete al CAAV y disfruta de todas las actividades, <br />
          lugares y experiencias que te ofrece nuestro entorno.
        </p>
      </div>

      <div className="container center contenedor_chico_entorno">
        <p className="titulo_1_nv" style={{ marginBottom: "0px" }}>
          ¿Vienes de otra ciudad?
        </p>
        <p>
          Si vienes de fuera, es probable que tengas muchas dudas, <br />
          como dónde vivir y cómo llegar al CAAV.
        </p>
        <p>
          Aquí te damos los links a algunas aplicaciones y páginas <br />
          que facilitarán tu estancia en Guadalajara.
        </p>
        <p>Encuentra en ellas hotel, departamento o estancia compartida.</p>
      </div>

      <div className="center">
        <p className="titulo_1_nv">¿Cómo llegar?</p>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3732.962817160152!2d-103.37224418507336!3d20.671091686193154!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428ae058d2f4fd3%3A0x209823d3fba8d0af!2sCAAV%20Universidad%20de%20Medios%20Audiovisuales!5e0!3m2!1ses-419!2smx!4v1643735295418!5m2!1ses-419!2smx"
          style={{ border: "none", borderRadius: "10px" }}
          className="contenedor_mapa"
          title="Mapa ubicación caav"
        ></iframe>
      </div>

      <div
        className="container"
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          flexWrap: "wrap",
        }}
      >
        <a href="https://moovitapp.com/guadalajara-2900/poi/es-419">
          <img src={logo1} className="imagen_cerca_1" alt="moovit" />
        </a>

        <a href="https://www.uber.com/mx/es/s/d/kochab/?utm_source=AdWords_Brand&utm_campaign=CM2057945-search-google-brand_113_-99_MX-National_driver_web_acq_cpc_es_Generic_Exact_uber_kwd-169801042_533398408706_127682484627_e_c&campaign_id=12520782377&adg_id=127682484627&fi_id=&match=e&net=g&dev=c&dev_m=&ad_id=533398408706&cre=533398408706&kwid=kwd-169801042&kw=uber&placement=&tar=&gclid=Cj0KCQjw0umSBhDrARIsAH7FCocA6pbBgzLEWga1HTTeT5uggPXzbX5PWtM-l7w0HE4A6_vLnoP9xc4aAt0mEALw_wcB&gclsrc=aw.ds">
          <img src={logo2} className="imagen_cerca_1 " alt="uber" />
        </a>

        <a href="https://www.googleadservices.com/pagead/aclk?sa=L&ai=DChcSEwjasdPA2Jn3AhUyIa0GHfTPCksYABAAGgJwdg&ae=2&ohost=www.google.com&cid=CAESbOD2NKUiwm2M15WsLir4OP9pbJZH6JZ1SLWYB7N1V4qF_gViwXlM3P2K7s3Io1L_PD3i2jYcy6WwNlzbLt7SMfRQ1EoVYUBiFUDxPv0HjwObSq_4BMIfK5mQ7tYynQkVYyREyd3tTfL_Z9nKNQ&sig=AOD64_3cMZfyTdy9NqDY1N6B06NUTU7h7A&q&adurl&ved=2ahUKEwii7sjA2Jn3AhW6IEQIHU7UDPoQ0Qx6BAgDEAE&dct=1">
          <img src={logo3} className="imagen_cerca_1" alt="didi" />
        </a>
      </div>

      <div className="video_1">
        <div className="contenedor_media ">
          <iframe
            className="media_contenido"
            src="https://www.youtube.com/embed/PoMPYRMg2pE"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            style={{ borderRadius: "10px" }}
          ></iframe>
        </div>
      </div>

      <div
        className="fondo_1"
        style={{ paddingTop: "1px", paddingBottom: "20px" }}
      >
        <p className="titulo_1_nv center" style={{ margin: "10px 0px 0px" }}>
          ¿Dónde hospedarte?
        </p>
        <div className="container contenedor_xch">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <a
              target="blank"
              className="imagen_cerca_3"
              href="https://www.airbnb.mx/"
            >
              <img src={logo4} style={{ width: "100%" }} alt="airbnb" />
            </a>

            <a
              target="blank"
              className="imagen_cerca_3"
              href="https://www.roomgo.com.mx/jalisco/en-renta-guadalajara"
            >
              <img src={logo7} style={{ width: "100%" }} alt="roomgo" />
            </a>

            <a
              target="blank"
              className="imagen_cerca_3"
              href="https://www.booking.com/"
            >
              <img src={logo5} style={{ width: "100%" }} alt="booking" />
            </a>

            <a
              target="blank"
              className="imagen_cerca_3"
              href="https://www.vivanuncios.com.mx"
            >
              <img src={logo6} style={{ width: "100%" }} alt="vivanuncios" />
            </a>

            <a
              target="blank"
              className="imagen_cerca_3"
              href="https://www.roomnette.com"
            >
              <img src={logo8} style={{ width: "100%" }} alt="roomnette" />
            </a>

            <a
              target="blank"
              className="imagen_cerca_3"
              href="https://www.couchsurfing.com"
            >
              <img src={logo9} style={{ width: "100%" }} alt="couchsurfing" />
            </a>
          </div>
        </div>

        <p
          className="titulo_4_nv center"
          style={{ marginTop: "0px", fontWeight: "bold" }}
        >
          Hoteles cercanos
        </p>
        <p className="center">
          BoHostel
          <br />
          Casa Bosque Eduviges
          <br />
          Casa Alexia
          <br />
          Hotel Lafayette
          <br />
          Hostal Casa Colores
          <br />
          Hotel Love It Consulado
          <br />
          Hostel Hospedarte Chapultepec
          <br />
        </p>
      </div>

      <p
        className="titulo_1_nv center"
        style={{ marginTop: "18px", marginBottom: "0px" }}
      >
        ¿Dónde comer?
      </p>
      <div className="container">
        <p className="center">
          Para empezar, lo mejor está en casa:
          <br />
          en nuestra sede de Griffith encontrarás la{" "}
          <span style={{ fontWeight: "bold" }}>Caavfetería</span>
        </p>
      </div>

      <div
        style={{
          justifyContent: "center",
          display: "flex",
          marginBottom: "0px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
            width: "1105px",
          }}
        >
          <img src={logo25} className="imagen_cerca_2" alt="Caavfeteria" />

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              marginBottom: "30px",
            }}
          >
            <div className="box_12">
              <p className="center">
                Un espacio abierto, al lado de la videosala, donde puedes
                disfrutar desayunos deliciosos y café exquisito para comenzar tu
                día con energía y una buena sonrisa.
                <br />
                Nuestra recomendación es pedir cualquiera de los lonches y café,
                para sentarte a disfrutar con la comunidad del CAAV.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <CarruselImagenes imagenes={imagenes} />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "40px 0",
        }}
      >
        <div className="boton_0_nv" style={{ margin: "0px", width: "auto" }}>
          <a
            target="blank"
            href="https://instagram.com/caavfeteria?igshid=Zjc2ZTc4Nzk="
          >
            <p>Visita su página de instagram</p>
          </a>
        </div>
      </div>

      <div
        style={{
          justifyContent: "center",
          display: "flex",
          marginBottom: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
            width: "1105px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "strech",
              flexWrap: "wrap",
            }}
          >
            <div className="box_1">
              <div>
                <img
                  src={logo11}
                  className="imagen_cerca_2"
                  alt="El molletero"
                />
              </div>
              <div>
                <p>
                  Es un local a una cuadra de la escuela.
                  <br />
                  Ofrecen el clásico platillo de desayuno y una variedad de
                  opciones novedosas que nos encantan. Nuestros favoritos son el
                  mollete de pierna y el de carne en su jugo (que además son
                  supertapatíos), el de cajeta con nuez y el de nutella con
                  fresas.
                </p>
              </div>
            </div>
            <div className="box_1_2">
              <div>
                <img
                  src={logo10}
                  className="imagen_cerca_2"
                  alt="Los abarrotes"
                />
              </div>
              <div>
                <p>
                  Una clásica tienda de abarrotes tapatía, con una señora que
                  cocina a diario en la plancha deliciosos lonches y sándwiches
                  de varios guisados. A nosotros nos encantan los de pastor con
                  frijoles y los de chilaquiles. Pero tienes que ir temprano
                  porque se acaban.
                </p>
              </div>
            </div>
            <div className="box_1_3">
              <div>
                <img
                  src={logo22}
                  className="imagen_cerca_2"
                  alt="Tacos el guero"
                />
              </div>
              <div>
                <p>
                  Estos tacos han estado enfrente de la universidad por años.
                  Prácticamente todos nuestros estudiantes y trabajadores han
                  comido ahí en algún momento. Nos encantan su agua de horchata
                  y sus tacos de adobada y de frijol con queso.
                </p>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "strech",
              flexWrap: "wrap",
            }}
          >
            <div className="box_1_5">
              <div>
                <img
                  src={logo12}
                  className="imagen_cerca_2"
                  alt="Peligro al fondo"
                />
              </div>
              <div>
                <p>
                  A dos cuadras de la universidad, es perfecto cuando tienes
                  tiempo para un desayuno o comida un poco más largos. El lugar
                  es acogedor y hay muchísimas plantas. Además es un lugar que
                  ha trabajado para crear un espacio para la comunidad
                  LGBTTTIQ+, y los sábados tienen el Sábado Jotero. Te
                  recomendamos el nasty french toast, todos sus chilaquiles y
                  las enchiladas de costilla.
                </p>
              </div>
            </div>
            <div className="box_1_6">
              <div>
                <img
                  src={logo24}
                  className="imagen_cerca_2"
                  alt="Espacio abierto"
                />
              </div>
              <div>
                <p>
                  Escondido detrás de un portón negro y un largo pasillo con
                  grava, Espacio abierto ofrece un escape de la ciudad. Además
                  de preparar café delicioso, tienen una variedad de pan dulce
                  que cambia con el tiempo. Te recomendamos los smoothie bowls,
                  el sándwich de chorizo confitado y las galletas de matcha.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="fondo_1" style={{ padding: "20px 0px", margin: "1px" }}>
        <p
          className="titulo_4_nv center"
          style={{ fontWeight: "bold", marginTop: "0px" }}
        >
          También tienes cerca:
        </p>

        <div className="flex_padre_cerca_1 container contenedor_chico">
          <img src={logo13} className="imagen_cerca_2" alt="Xitzin Cafe" />
          <img src={logo14} className="imagen_cerca_2" alt="Tomate" />
          <img src={logo15} className="imagen_cerca_2" alt="La boca" />
          <img src={logo16} className="imagen_cerca_2" alt="Cars Jr" />
          <img src={logo17} className="imagen_cerca_2" alt="Quin" />
          <img src={logo19} className="imagen_cerca_2" alt="BBQ" />
          <img src={logo20} className="imagen_cerca_2" alt="Love It" />
          <img src={logo21} className="imagen_cerca_2" alt="deli salads" />
          <img src={logo18} className="imagen_cerca_2" alt="Mr. sushi" />
        </div>
      </div>
    </div>
  );
};

export default Entorno;
