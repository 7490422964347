import React, { Fragment, useEffect } from "react";
import M from "materialize-css";

import "./Somos.css";

import CarruselImagenes from "../../Components/CarruselImagenes/CarruselImagenes";
import imagenes from "../../Components/CarruselImagenes/Imagenes/ImagenesCarruselSomos";

import img1 from "../../Images/escritorio/Somos/1.png";
import movil1 from "../../Images/movil/Somos/1.png";

import img3 from "../../Images/Icon/5.png";
import img4 from "../../Images/Icon/7.png";
import img5 from "../../Images/Icon/6.png";
import img6 from "../../Images/logos/logo_2.svg";

const Somos = () => {
  useEffect(() => {
    var elems = document.querySelectorAll(".carousel");
    M.Carousel.init(elems, {
      fullWidth: true,
      indicators: true,
    });
  });

  return (
    <Fragment>
      {window.screen.width > 550 ? (
        <img src={img1} style={{ width: "100%" }} />
      ) : (
        <img src={movil1} style={{ width: "100%" }} />
      )}
      <p className="encabezadosCaav center" style={{ margin: "15px" }}>
        <span className="encabezadosMonserrat">Somos quienes saben que</span>
        <br />
        eres lo que tú creas.
      </p>

      <div className="container center texto_movil">
        <p>
          Somos quienes cuentan historias. <br />
          Con la pluma, con la cámara, con la luz y el sonido, <br />
          con el movimiento y el ritmo, con música y con color, <br />
          contamos lo que soñamos.
        </p>
        <p>
          Somos quienes atrapan imágenes: <br />
          buscamos eso que se ve y nunca se olvida. Eso que conmueve y provoca.{" "}
          <br />
          Buscamos capturar momentos únicos y convertirlos en belleza. <br />
          Queremos fotografiar, filmar, animar, grabar, mezclar, editar,
          proyectar y compartir <br />
          lo que nadie ha visto, lo que nadie sabía.
        </p>
        <p>
          Somos quienes quieren saber y, cuando no sepamos, <br />
          quienes inventan, quienes suman, quienes intuyen.
        </p>
        <p>
          Somos quienes comenzaron todo, quienes han ido un paso adelante,{" "}
          <br />
          las personas más curiosas, las más aventuradas, las que están siempre
          en movimiento.
        </p>
        <p style={{ marginBottom: "0px" }}>
          Somos el CAAV, el espacio para creer y crear.
        </p>

        <img
          src={img6}
          alt="#YoCreo"
          style={{ width: "200px", padding: "2px 20px 20px 20px" }}
        />
      </div>

      <div style={{ marginTop: "-6px" }}>
        <p
          className="titulo_1_nv"
          style={{ textAlign: "center", margin: "2% 0px 1%" }}
        >
          Éste es nuestro manifiesto
        </p>
        <div style={{ textAlign: "center" }}>
          <div className="container">
            <p>
              Cuando miro, transformo. <br />
              Cuando entiendo, reinvento. <br />
              Cuando creo, conozco mundos nuevos.
            </p>
            <p>
              Quiero contar las historias que importan. <br />
              Recrear las historias que importan. <br />
              Escuchar las historias que importan.
            </p>
            <p>
              He decidido aprender las palabras, <br />
              los trucos, las maneras de hacer y de ser. <br />
              He decidido transformarme en alguien <br />
              que sabe hacerse escuchar, <br />
              que se gana la atención de las personas.
            </p>
            <p>
              Alguien que dice: yo creo. <br />
              Yo creo historias. Yo creo en la verdad. <br />
              Yo creo imágenes que se quedan en el corazón. <br />
              Yo creo en la imaginación. <br />
              Yo creo con luz y con palabras. <br />
              Yo creo en transformarlo todo.
            </p>
            <p>Yo creo y, cuando creo, todo cambia.</p>
          </div>
        </div>
      </div>

      <div>
        <p
          className="titulo_1_nv"
          style={{
            textAlign: "center",
            marginBottom: "10px",
            marginTop: "20px",
          }}
        >
          Cada día es una nueva historia
        </p>
        <div className="container">
          <CarruselImagenes imagenes={imagenes} />
        </div>
      </div>

      <p
        className="titulo_1_nv"
        style={{ textAlign: "center", marginBottom: "10px", marginTop: "20px" }}
      >
        Nuestra propuesta académica
      </p>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "15px",
        }}
      >
        <div className="container contenedor_xch">
          <p>
            <b>Somos creadores de historias</b>
            <br />
            El CAAV nació en 1995 como el Centro de Artes Audiovisuales, y en
            2003 creamos la licenciatura en Medios Audiovisuales, con lo que nos
            convertimos en la primera universidad en el país en ofrecer
            enseñanza especializada en esta área.
          </p>
          <p>
            <b>Accesibilidad y tiempos reducidos</b>
            <br />
            Nuestros planes de estudios están diseñados para que culmines tu
            licenciatura en sólo tres años. Por eso nuestro plan es
            cuatrimestral, con espacios de tiempo cortos entre un nivel y otro.
            Además cuidamos que nuestros precios sean lo más accesibles posible,
            para darte educación de alto nivel sin altos costos.
          </p>
          <p>
            <b>Qué encontrarás aquí</b>
            <br />
            El espacio para crear y creer en ti, para conocer, para convivir,
            para disfrutar y para crecer con gente como tú, con tus mismos
            intereses e ideales; para ser quien quieres ser, para prepararte
            profundamente en conceptos teóricos y prácticos de tu campo de
            estudio.
          </p>
          <p>
            <b>Nuestro propósito</b>
            <br />
            Nuestro fin fundamental es formarte como una extraordinaria o
            extraordinario <i>storyteller</i>, independientemente del medio que
            elijas para narrar tus historias o de los recursos de los que
            dispongas.
          </p>
          <p>
            <b>Educación continua</b>
            <br />
            Además, al cursar tu licenciatura tendrás acceso, también sin costo
            adicional,{" "}
            <b>a más de 130 cursos, talleres y diplomados especializados.</b>
          </p>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="boton_0_nv" style={{ margin: "0px" }}>
              <a href="/cursos-diplomados">
                <p>Conócelos aquí</p>
              </a>
            </div>
          </div>

          <p>
            <b>Docentes, equipo y planes siempre actualizados</b>
            <br />
            Todos nuestros profesores y profesoras son profesionales activos.
            Además de estar preparados en los conceptos teóricos de sus
            asignaturas, están en actualización permanente, porque trabajan a
            diario en sus campos de conocimiento.
          </p>
          <p>
            En el CAAV constantemente estamos renovando nuestros planes de
            estudio, equipo e ideas, para prepararte para una realidad fluida,
            que exige evolución permanente.
          </p>
          <p>
            <b>
              Tu titulación ocurre mientras estudias y no es un gasto inmenso
            </b>
            <br />A diferencia de las demás universidades, en el CAAV preparas
            tu titulación durante tu último cuatrimestre, y su costo es
            estrictamente el indispensable, porque queremos que nada te detenga
            para culminar tu sueño de tener una licenciatura.
          </p>
          <p>
            Todas nuestras licenciaturas tienen validez ante la Secretaría de
            Educación Pública.
          </p>
          <p>
            <b>Un campo de trabajo cada vez más vasto</b>
            <br />
            En un mundo que cada vez consume y requiere más y más medios
            audiovisuales para el entretenimiento, el comercio y la vida
            cotidiana, tu campo de trabajo se irá haciendo cada vez más amplio,
            con mayor demanda y mejores ingresos.
          </p>
          <p>
            <b>Deseamos tu realización</b>
            <br />
            Queremos prepararte para desenvolverte en el mundo, para que
            aproveches todas las oportunidades que se te presenten en las artes
            audiovisuales y de la comunicación, y para que puedas tener esa vida
            que tanto sueñas, haciendo algo que aporte a los demás y que ames
            profundamente.
          </p>
        </div>
      </div>

      <div className="fondo_4" style={{ padding: "1px 0%" }}>
        <p
          className="titulo_1_nv"
          style={{ textAlign: "center", margin: "10px 0px" }}
        >
          Qué tenemos en casa
        </p>
        <div
          className="contenedor_1_nosotros flex_padre_1_nosotros"
          style={{ marginBottom: "10px" }}
        >
          <div className="box_1_nosotros">
            <p>
              • Aulas equipadas
              <br />
              • Espacios de convivencia
              <br />
              • Biblioteca
              <br />
              • Videoteca
              <br />
              • Centro de cómputo
              <br />
              • Laboratorio de audio
              <br />
            </p>
          </div>

          <div className="box_1_nosotros">
            <p>
              • Laboratorio de fotografía
              <br />
              • Laboratorio de luz
              <br />
              • Unidad de producción
              <br />
              • Foro de televisión
              <br />
              • Videosala
              <br />
              • Caavfetería
              <br />
            </p>
          </div>
        </div>
      </div>

      <div style={{ padding: "1px 0%" }}>
        <div className="center">
          <p className="titulo_1_nv" style={{ margin: "15px 0px" }}>
            Horarios de clases
          </p>

          <p className="box_1_nosotros">
            De lunes a viernes, de 8:00 am a 3:00 pm.
            <br />
            Pueden programarse algunas sesiones el sábado.
          </p>
        </div>
      </div>

      <div className="fondo_9">
        <div style={{ padding: "1px 20%" }}>
          <p className="encabezadosCaav center" style={{ marginTop: "13px" }}>
            <span style={{ color: "#A80938" }}>Nuestras sedes</span> <br />
            <span className="encabezadosMonserrat">
              Guadalajara, Jalisco, México.
            </span>
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "stretch",
              flexWrap: "wrap",
            }}
          >
            <div className="caja_1_nosotros">
              <div className="center">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img src={img3} className="imagen_nosotros_1"></img>
                </div>
                <p
                  className="titulo_4_nv"
                  style={{ color: "#A80938", margin: "0px" }}
                >
                  Griffith
                </p>
                <p style={{ margin: "0px" }}>
                  Lerdo de Tejada 2071,
                  <br />
                  Colonia Americana
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="boton_1_nv" style={{ width: "100px" }}>
                  <a href="https://youtu.be/mP7nc-IDPYo" target="blanck">
                    <p>Conócela</p>
                  </a>
                </div>
              </div>
            </div>

            <div className="caja_1_nosotros">
              <div className="center">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img src={img4} className="imagen_nosotros_1"></img>
                </div>
                <p
                  className="titulo_4_nv"
                  style={{ color: "#A80938", margin: "0px" }}
                >
                  Gance
                </p>
                <p style={{ margin: "0px" }}>
                  Lerdo de Tejada 2091,
                  <br />
                  esquina con Marsella,
                  <br />
                  Colonia Americana.
                </p>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="boton_1_nv" style={{ width: "100px" }}>
                  <a href="https://youtu.be/xOH5b8r8lC8" target="blanck">
                    <p>Conócela</p>
                  </a>
                </div>
              </div>
            </div>

            <div className="caja_1_nosotros">
              <div className="center">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img src={img5} className="imagen_nosotros_1"></img>
                </div>
                <p
                  className="titulo_4_nv"
                  style={{ color: "#A80938", margin: "0px" }}
                >
                  Godard
                </p>

                <p style={{ margin: "0px" }}>
                  Suiza 21,
                  <br />
                  Colonia Moderna
                </p>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="boton_1_nv" style={{ width: "100px" }}>
                  <a href="https://youtu.be/s9_v4RkrpPE" target="blanck">
                    <p>Conócela</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="center container_chico_movil">
          <p className="titulo_4_nv" style={{ marginBottom: "0px" }}>
            ¡Próximamente tendremos nueva sede!
          </p>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className="boton_1_nv">
              <a href="/elcaavsemueve">
                <p>Da un vistazo</p>
              </a>
            </div>
          </div>
          {/* <p style={{marginBottom:"25px", marginTop:"0px"}}>
                        Guadalajara, Jalisco, México
                    </p> */}
        </div>
      </div>
    </Fragment>
  );
};

export default Somos;
