import React, { useEffect, useState, Fragment } from "react";
import M from "materialize-css";
import Loader from "../../Components/Loader/Loader";

import "./NuevaSede.css";
import "../ComoReinscibirte/Reinscripcion.css";

import img1 from "../../Images/escritorio/NuevaSede/1.png";
import img2 from "../../Images/escritorio/NuevaSede/2.png";
import movil1 from "../../Images/movil/NuevaSede/1.png";

import FotosHelper from "../../Helpers/Fotos";
import Swal from "sweetalert2";

const NuevaSede = () => {
  const [imagenes, setImagenes] = useState([]);
  const [actual, setActual] = useState(0);
  const [flag, setFlag] = useState(false);
  const [preguntas, setPreguntas] = useState([
    {
      titulo: "¿Qué hay que hacer?",
      pregunta: (
        <p>
          Conforme se acerque la fecha, te iremos indicando en esta sección los
          pasos a seguir. Mientras tanto, sólo queremos que sepas que este
          cambio ya será muy pronto y que será un gran paso hacia adelante.
        </p>
      ),
      estado: false,
    },
    {
      titulo: "¿Cuál será el horario del campus?",
      pregunta: (
        <p>
          El horario del campus será el mismo que tienen nuestras sedes
          actuales: <br />
          de lunes a viernes de 8 de la mañana a 9 de la noche y sábados de 10
          de la mañana a 1:30 de la tarde.
        </p>
      ),
      estado: false,
    },
    {
      titulo:
        "¿Cuál será nuestro horario de clases?, ¿los recesos serán los mismos?",
      pregunta: (
        <p>
          Se mantendrá la misma estructura de horario, tanto para licenciaturas
          como para talleres, cursos y diplomados.
        </p>
      ),
      estado: false,
    },
    {
      titulo: "¿Cambiarán los precios de las licenciaturas?",
      pregunta: (
        <p>
          No, este cambio no va a influir en el precio de las licenciaturas ni
          de cursos, talleres o diplomados.
        </p>
      ),
      estado: false,
    },
    {
      titulo: "¿Cómo tramito mi nueva credencial?, ¿tiene costo?",
      pregunta: (
        <p>
          La primera credencial que se emita para el nuevo edificio no tendrá
          costo. En caso de que la pierdas, cada reposición tendrá costo.
        </p>
      ),
      estado: false,
    },
    {
      titulo: "¿Qué pasa si pierdo u olvido mi credencial?",
      pregunta: (
        <p>
          Es necesario que tramites una nueva credencial. En lo que haces este
          trámite y la recibes, debes llevar una identificación oficial para
          poder ingresar.
        </p>
      ),
      estado: false,
    },
    {
      titulo: "¿Qué pasará con Godard, Gance y Griffith?",
      pregunta: (
        <p>
          La sede Gance cerrará en cuanto nos mudemos. Griffith se mantendrá
          abierta para oficinas y biblioteca, y en ella ya no habrá clases.
          Algunas clases se mantendrán en Godard por un tiempo pero la mayoría
          se impartirán en la nueva sede.
        </p>
      ),
      estado: false,
    },
    {
      titulo: "¿Ya no habrá ninguna clase en las sedes anteriores?",
      pregunta: (
        <p>
          Se seguirán impartiendo en algunas clases en Godard, sólo
          temporalmente, A quienes tendrán esas clases, les informaremos con
          tiempo.
        </p>
      ),
      estado: false,
    },
    {
      titulo: "¿En dónde se tomarán los cursos, talleres y diplomados?",
      pregunta: (
        <p>
          En el nuevo campus. Sólo se seguirá impartiendo temporalmente en
          Godard: <br />
          LISTA DE ASIGNATURAS DE LICENCIATURA Y CURSOS
        </p>
      ),
      estado: false,
    },
    {
      titulo: "¿Qué rutas de transporte público me acercan al nuevo campus?",
      pregunta: <p></p>,
    },
    {
      titulo: "¿Hay estaciones de MiBici cerca del nuevo campus?",
      pregunta: <p>Sí, próximamente te daremos más detalles.</p>,
    },
    {
      titulo: "¿En dónde puedo estacionar mi auto?",
      pregunta: (
        <p>
          Podrás estacionarte en varias de las calles aledañas que son libres de
          parquímetro.
        </p>
      ),
      estado: false,
    },
    {
      titulo: "¿Si soy profe, necesito credencial?",
      pregunta: (
        <p>
          Si, todas las personas que entremos al edificio requerimos una
          credencial emitida por la universidad, de modo que el acceso sea
          controlado, por la seguridad de todas y todos.
        </p>
      ),
      estado: false,
    },
    {
      titulo: "¿Pueden entrar visitantes?",
      pregunta: (
        <p>
          Claro que sí. El ingreso debe solicitarse a la entrada y se entregará
          un pase de acceso especial para estas personas.
        </p>
      ),
      estado: false,
    },
    {
      titulo: "¿La CAAVfetería seguirá ahí?",
      pregunta: (
        <p>
          ¡Por supuesto! La CAAVfetería se mudará con nosotros, y tendrá una
          nueva personalidad y un menú más amplio.
        </p>
      ),
      estado: false,
    },
  ]);

  useEffect(() => {
    let elems2 = document.querySelectorAll(".collapsible");
    M.Collapsible.init(elems2);

    obtenerFotos();
  }, []);

  const obtenerFotos = async () => {
    let res = await FotosHelper.ObtenerFotosCampo("1");

    if (res.success) {
      setImagenes(res.data);
      setFlag(true);

      setTimeout(() => {
        var elems = document.querySelectorAll(".materialboxed");
        M.Materialbox.init(elems);
      }, 1000);
    } else {
      Swal.fire(
        "Error!",
        "No se pudo obtener las imagenes, codigo: " + res.code,
        "error"
      );
    }
  };

  const cambiarColapsible = (index) => {
    const copiaPreguntas = [...preguntas];

    copiaPreguntas[index].estado = !copiaPreguntas[index].estado;

    setPreguntas(copiaPreguntas);
  };

  return (
    <Fragment>
      <section>
        {window.screen.width > 550 ? (
          <img src={img1} style={{ width: "100%" }} alt="Nueva sede del caav" />
        ) : (
          <img
            src={movil1}
            style={{ width: "100%" }}
            alt="Nueva sede del caav"
          />
        )}
        <div className="container contenedor_chico center">
          <p style={{ fontFamily: "Montserrat-Bold" }} className="titulo_4_nv">
            <span style={{ color: "#de2657" }}>¿Qué está pasando?</span>
          </p>
          <h1 className="titulo_1_nv" style={{ marginTop: "0" }}>
            #ElCAAVseMueve
          </h1>
        </div>

        <div
          className="container contenedor_chico"
          style={{ maxWidth: "642px" }}
        >
          <div className="contenedor_media">
            <iframe
              className="media_contenido"
              src="https://www.youtube.com/embed/yoqaOaeOU0A"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              style={{ borderRadius: "10px" }}
            ></iframe>
          </div>

          <div className="contenedor_media" style={{ marginTop: "40px" }}>
            <iframe
              className="media_contenido"
              src="https://www.youtube.com/embed/qZkDj1F1I58"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              style={{ borderRadius: "10px" }}
            ></iframe>
          </div>
        </div>
      </section>

      <section className="in-fondo-4" style={{ marginTop: "20px" }}>
        <div className="center container contenedor_movil_chico_re">
          <h2
            className="titulo_4_nv"
            style={{
              fontFamily: "Montserrat-Bold",
              color: "#164C95",
              textAlign: "center",
              margin: "0",
            }}
          >
            ¿Cómo?
          </h2>
          <p>¡Nos vamos al nuevo campus!</p>

          <h2
            className="titulo_4_nv"
            style={{
              fontFamily: "Montserrat-Bold",
              color: "#164C95",
              textAlign: "center",
              margin: "0",
            }}
          >
            ¿Cuándo?
          </h2>
          <p>
            La obra está casi concluida. <br />
            Podremos utilizarla en cuanto tengamos todos <br />
            los permisos de las autoridades correspondientes <br />
            para garantizar tu seguridad, comodidad y accesibilidad.
            <br />
            <br />
            Te lo haremos saber en el momento en que eso suceda.
          </p>

          <h2
            className="titulo_4_nv"
            style={{
              fontFamily: "Montserrat-Bold",
              color: "#164C95",
              textAlign: "center",
              margin: "0",
            }}
          >
            ¿A dónde?
          </h2>
          <p>
            La ubicación exacta te la daremos a conocer
            <br />
            en unos días, pero lo que podemos decirte
            <br />
            es que estamos en la misma colonia Americana,
            <br />
            muy cerca de Griffith y de Gance.
          </p>
        </div>
      </section>

      <section>
        <div className="center container contenedor_movil_chico_re">
          <p>
            <span
              className="titulo_4_nv"
              style={{ fontFamily: "Montserrat-Bold" }}
            >
              El nuevo campus es una realidad
              <br />y será un espacio digno de tus sueños más grandes.
            </span>
            <br />
            <br />
            Cruzar este periodo de construcción
            <br />
            ha sido uno de los desafíos
            <br />
            más grandes que hemos enfrentado.
            <br />
            Y todo ha valido la pena.
            <br />
            <br />
            El protagonista de la historia del CAAV
            <br />
            siempre vas a ser tú.
            <br />
            Todo nuestro esfuerzo ha sido para darte el espacio
            <br />
            en donde quepan tus sueños,
            <br />
            tus ganas de crecer y todos tus proyectos.
            <br />
            <br />
            Nos vamos a nuestro nuevo campus.
            <br />
            Será una dicha inmensa recibirte ahí
            <br />a partir del lunes 13 de enero.
          </p>
        </div>

        <div
          className="center container contenedor_movil_chico_re"
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
          }}
        >
          <img
            src={img2}
            alt="Mapa, ubicación nueva sede"
            style={{ width: "100%", maxWidth: "642px", borderRadius: "10px" }}
          />
        </div>
      </section>

      <section>
        <div
          style={{ maxWidth: "642px", marginTop: "20px" }}
          className="center container contenedor_movil_chico_re"
        >
          {!flag ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "60vh",
              }}
            >
              <Loader />
            </div>
          ) : (
            <>
              <img
                src={imagenes[actual].imagen}
                alt="Mapa, ubicación nueva sede"
                className="materialboxed"
                style={{
                  width: "100%",
                  maxWidth: "642px",
                  borderRadius: "10px",
                }}
              />
              <div className="flex_mueve">
                {imagenes.map((imagen, index) => (
                  <div
                    key={index}
                    className="hijo_1_mueve"
                    style={{ borderRadius: "10px" }}
                  >
                    <img
                      src={imagen.imagen}
                      className="imagenes_mueve"
                      onClick={() => {
                        setActual(index);
                      }}
                      alt={imagen.titulo}
                    />
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </section>

      <section className="in-fondo-4" style={{ marginTop: "20px" }}>
        <div className="center container contenedor_movil_chico_re">
          <ul
            className="collapsible"
            style={{
              boxShadow: "none",
              border: "none",
              display: "flex",
              flexDirection: "column",
              gap: "15px",
            }}
          >
            {preguntas.map((pregunta, index) => (
              <li
                key={index}
                style={{ borderRadius: "10px", background: "#ffffff" }}
              >
                <div
                  className="collapsible-header collapsible-header_propio"
                  style={{
                    justifyContent: "space-between",
                    background: "transparent",
                    border: "none",
                    padding: "10px 20px",
                  }}
                  onClick={() => {
                    cambiarColapsible(index);
                  }}
                >
                  <p
                    className="titulo_4_nv"
                    style={{ fontFamily: "Montserrat-Bold", color: "#000000" }}
                  >
                    {pregunta.titulo}
                  </p>
                  {pregunta.estado ? (
                    <i
                      id="add_circle"
                      className="material-icons add_circle"
                      style={{ color: "#3F150B" }}
                    >
                      add_circle
                    </i>
                  ) : (
                    <i
                      id="do_not_disturb_on"
                      className="material-icons do_not_disturb_on"
                      style={{ color: "#3F150B" }}
                    >
                      do_not_disturb_on
                    </i>
                  )}
                </div>
                <div className="collapsible-body" style={{ textAlign: "left" }}>
                  {pregunta.pregunta}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </section>
    </Fragment>
  );
};

export default NuevaSede;
