import React, { useEffect, useState } from "react";
import img_whats from "../Images/escritorio/Cursos/iconos/whats.svg";

import PopHelper from "../Helpers/Pop";

const Mensaje = () => {
  const [popFlag, setPopFlag] = useState(true);
  const [pop, setPop] = useState({ fecha: "cargando..." });

  useEffect(() => {
    obtenerPop();
  }, []);

  const obtenerPop = async () => {
    let res = await PopHelper.obtenerPop();

    if (res.success) {
      setPop(res.data);
    } else {
      setPopFlag(false);
    }
  };

  return (
    popFlag && (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          className="contenedor_pop_curso"
          onClick={() => {
            setPopFlag(false);
          }}
        ></div>
        <div className="pop_curso center">
          <div
            className="pop_salir"
            onClick={() => {
              setPopFlag(false);
            }}
          />
          <p className="titulo_pop" style={{ color: "#F9007A", margin: "0" }}>
            Aviso
          </p>
          <p>
            Los cursos que aparecen aquí son los próximos inicios. <br />
            Estamos actualizando nuestro catálogo de los siguientes meses.{" "}
            <br />
            Si deseas información de uno en particular, por favor escríbenos.
          </p>
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <a
              className="boton_curso"
              target="_blank"
              href={
                "https://api.whatsapp.com/send?phone=3322351431&text=Hola, quiero información sobre un curso que no aparece en su página web, por favor."
              }
              rel="noreferrer"
            >
              <img src={img_whats} alt="WhatsApp" />
              <p style={{ margin: "0px" }}>Abrir WhatsApp</p>
            </a>
          </div>
        </div>
      </div>
    )
  );
};

export default Mensaje;
