import React from "react";

import FormInscripcion from "../../Components/InscripcionComponents/FormInscripcion/FormInscripcion";
import CarruselPasos from "../../Components/InscripcionComponents/CarruselPasos/CarruselPasos";
import CarruselPagos from "../../Components/InscripcionComponents/CarruselPagos/CarruselPagos";

import img1 from "../../Images/escritorio/Inscripcion/1.png";
import movil1 from "../../Images/movil/Inscripcion/1.png";

import "./Inscripcion.css";

const Inscripcion = () => {
  const data = {
    primeraFecha: "9 de enero",
    segundaFecha: "13 de enero",
    terceraFecha: "12 de mayo y 1 de septiembre de 2025",
    cuartaFecha: "1 de septiembre de 2025",
    quintaFecha: "5",
    primerPrecio: "2,750",
    segundoPrecio: "3,750",
    tercerPrecio: "5,500",
    cuartoPrecio: "6,050",
    quintoPrecio: "22,000",
    sextoPrecio: "18,800",
    // sextaFecha: "23 de enero de 2025.",
    septimoPrecio: "2,600",
    octavoPrecio: "3,000",
    novenoPrecio: "5,200",
    decimoPrecio: "5,720",
    onceavo: "20,800",
    doceavo: "17,800",
  };

  return (
    <>
      {window.innerWidth > 550 ? (
        <img
          alt="Universidad de medios audiovisuales"
          src={img1}
          style={{ width: "100%" }}
        />
      ) : (
        <img
          alt="Universidad de medios audiovisuales"
          src={movil1}
          style={{ width: "100%" }}
        />
      )}

      <section className="center container contenedor_movil_chico_re">
        <h1
          className="titulo_1_nv"
          style={{ marginBottom: "0", marginTop: "15px" }}
        >
          Cómo inscribirte
        </h1>
        <p className="titulo_4_nv" style={{ fontFamily: "Montserrat-Bold" }}>
          <span style={{ color: "#DE2657" }}>
            ¡Qué alegría recibirte <br className="invisible_escritorio" /> en
            esta casa de estudios!
          </span>{" "}
          <br />
        </p>

        <div className="row">
          {/* <div
            className="col s12 m10 l10 offset-m1 offset-l1 center rein-fondo-1"
            style={{ flexDirection: "column" }}
          >
            <p
              className="titulo_4_nv"
              style={{
                fontFamily: "Montserrat-Bold",
                color: "#ffffff",
                margin: "0",
              }}
            >
              ¡Inscríbete antes <br className="invisible_escritorio" />
              del {data.primeraFecha}!
            </p>
            <p
              className="font-medium"
              style={{ color: "#ffffff", margin: "0" }}
            >
              Así aseguras tu lugar y pagas menos. <br />
              ¡Tenemos cupo limitado!
            </p>
          </div> */}
          <div
            className="col s12 m10 l10 offset-m1 offset-l1 center rein-fondo-2"
            style={{ flexDirection: "column" }}
          >
            <p
              className="titulo_4_nv"
              style={{
                fontFamily: "Montserrat-Bold",
                color: "#ffffff",
                margin: "0",
              }}
            >
              Iniciamos <br className="invisible_escritorio" />
              el {data.segundaFecha}
            </p>
            <p
              className="font-medium"
              style={{ color: "#ffffff", margin: "0" }}
            >
              Cine, Animación, Multimedia.
              {/* <br className="invisible_escritorio" /> y Marketing Digital. */}
            </p>
          </div>

          <div className=" col s12">
            <h2
              className="titulo_4_nv"
              style={{ fontFamily: "Montserrat-Bold" }}
            >
              Siguientes inicios:
            </h2>
            <p style={{ fontFamily: "Montserrat-Bold" }}>
              <span style={{ color: "#DE2657" }} className="titulo_4_nv">
                Cine, Animación y Multimedia
              </span>
              <br />
              {data.terceraFecha}
            </p>
            <p style={{ fontFamily: "Montserrat-Bold" }}>
              <span style={{ color: "#164C95" }} className="titulo_4_nv">
                Marketing Digital
              </span>
              <br />
              {data.cuartaFecha}
            </p>
          </div>
        </div>
      </section>

      <section className="contenedro-carrusle-1 in-fondo-4 center">
        <h2
          className="titulo_4_nv"
          style={{ fontFamily: "Montserrat-Bold", marginTop: "0" }}
        >
          Pasos a seguir:
        </h2>
        <CarruselPasos />
      </section>

      <section className="center container contenedor_movil_chico_re">
        <h2
          id="precios"
          className="titulo_4_nv"
          style={{
            fontFamily: "Montserrat-Bold",
            textAlign: "center",
          }}
        >
          Precios <br className="invisible_escritorio" /> cuatrimestrales
        </h2>
        <h2
          className="titulo_4_nv"
          style={{
            fontFamily: "Montserrat-Bold",
            color: "#DE2657",
            textAlign: "center",
            marginTop: "0",
          }}
        >
          Cine Digital, Animación <br className="invisible_escritorio" />y{" "}
          Multimedia
        </h2>

        <div className="row">
          <div
            className="col s12 m10 l10 offset-m1 offset-l1 center"
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0",
            }}
          >
            <div className="col s12 m6 l6 center rein-fondo-7">
              <div>
                <p
                  className="titulo_4_nv"
                  style={{
                    fontFamily: "Montserrat-Bold",
                    color: "#DE2657",
                    marginBottom: "0",
                  }}
                >
                  Inscripción
                </p>
                <p className="titulo_1_nv">${data.primerPrecio} MXN</p>
                {/* <p style={{ marginBottom: "0" }}>
                  Hasta el {data.primeraFecha} <br />
                  <span
                    className="titulo_4_nv"
                    style={{ fontFamily: "Montserrat-Bold" }}
                  >
                    ${data.primerPrecio} MXN
                  </span>
                </p>
                <p>
                  Después del <br className="invisible_escritorio" />
                  {data.primeraFecha} <br />
                  <span
                    className="titulo_4_nv"
                    style={{ fontFamily: "Montserrat-Bold" }}
                  >
                    ${data.segundoPrecio} MXN
                  </span>
                </p> */}
              </div>
            </div>
            <div className="col s12 m6 l6 center rein-fondo-7">
              <div>
                <p
                  className="titulo_4_nv"
                  style={{
                    fontFamily: "Montserrat-Bold",
                    color: "#DE2657",
                    marginBottom: "0",
                  }}
                >
                  4 colegiaturas
                </p>
                <p style={{ marginBottom: "0" }}>
                  Antes del día {data.quintaFecha}
                  <br className="invisible_escritorio" /> de cada mes
                  <br />
                  <span
                    className="titulo_4_nv"
                    style={{ fontFamily: "Montserrat-Bold" }}
                  >
                    ${data.tercerPrecio} MXN
                  </span>
                </p>
                <p>
                  Después del día {data.quintaFecha}
                  <br className="invisible_escritorio" /> de cada mes
                  <br />
                  <span
                    className="titulo_4_nv"
                    style={{ fontFamily: "Montserrat-Bold" }}
                  >
                    ${data.cuartoPrecio} MXN
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div
            style={{ marginTop: "15px" }}
            className="col s12 m10 l10 offset-m1 offset-l1 center rein-fondo-8"
          >
            <div>
              <p
                className="titulo_4_nv"
                style={{
                  fontFamily: "Montserrat-Bold",
                  color: "#ffffff",
                  marginBottom: "0",
                }}
              >
                Si cubres las cuatro colegiaturas de contado,
                <br />
                en lugar de ${data.quintoPrecio} MXN, pagas sólo $
                {data.sextoPrecio} MXN,
                <br />
                además de tu inscripción cuatrimestral,
              </p>
              {/* NOTA: aqui debemos de cambiar la informacion de sextaFecha y crear una nueva con el nombre de sextaFechaDia */}
              <p style={{ color: "#ffffff" }}>
                liquidando los primeros 10 días naturales del cuatrimestre.
                <br />
                Precios válidos para el calendario 2025 A, sujetos a cambios
                {/* liquidando antes antes del{" "}
                <br className="invisible_escritorio" />
                <b> {data.sextaFecha}</b> <br />
                Además de tu inscripción cuatrimestral. */}
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="in-fondo-4">
        <div className="center container contenedor_movil_chico_re">
          <h2
            className="titulo_4_nv"
            style={{
              fontFamily: "Montserrat-Bold",
              color: "#164C95",
              textAlign: "center",
              marginTop: "0",
            }}
          >
            Marketing Digital Creativo
          </h2>

          <div className="row" style={{ margin: "0" }}>
            <div
              className="col s12 m10 l10 offset-m1 offset-l1 center"
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0",
              }}
            >
              <div className="col s12 m6 l6 center rein-fondo-7">
                <div>
                  <p
                    className="titulo_4_nv"
                    style={{
                      fontFamily: "Montserrat-Bold",
                      color: "#02B5C1",
                      marginBottom: "0",
                    }}
                  >
                    Inscripción
                  </p>
                  <p className="titulo_1_nv">${data.septimoPrecio} MXN</p>

                  {/* <p style={{ marginBottom: "0" }}>
                    Hasta el {data.primeraFecha} <br />
                    <span
                      className="titulo_4_nv"
                      style={{ fontFamily: "Montserrat-Bold" }}
                    >
                      ${data.septimoPrecio} MXN
                    </span>
                  </p>
                  <p>
                    Después del {data.primeraFecha} <br />
                    <span
                      className="titulo_4_nv"
                      style={{ fontFamily: "Montserrat-Bold" }}
                    >
                      ${data.octavoPrecio} MXN
                    </span>
                  </p> */}
                </div>
              </div>
              <div className="col s12 m6 l6 center rein-fondo-7">
                <div>
                  <p
                    className="titulo_4_nv"
                    style={{
                      fontFamily: "Montserrat-Bold",
                      color: "#02B5C1",
                      marginBottom: "0",
                    }}
                  >
                    4 colegiaturas
                  </p>
                  <p style={{ marginBottom: "0" }}>
                    Antes del día {data.quintaFecha}
                    <br className="invisible_escritorio" /> de cada mes
                    <br />
                    <span
                      className="titulo_4_nv"
                      style={{ fontFamily: "Montserrat-Bold" }}
                    >
                      ${data.novenoPrecio} MXN
                    </span>
                  </p>
                  <p>
                    Después del día {data.quintaFecha}
                    <br className="invisible_escritorio" /> de cada mes
                    <br />
                    <span
                      className="titulo_4_nv"
                      style={{ fontFamily: "Montserrat-Bold" }}
                    >
                      ${data.decimoPrecio} MXN
                    </span>
                  </p>
                </div>
              </div>
            </div>

            <div className="col s12 m10 l10 offset-m1 offset-l1 center rein-fondo-9">
              <div>
                <p
                  className="titulo_4_nv"
                  style={{
                    fontFamily: "Montserrat-Bold",
                    color: "#ffffff",
                    marginBottom: "0",
                  }}
                >
                  Si cubres las cuatro colegiaturas de contado,
                  <br />
                  en lugar de ${data.onceavo} MXN, pagas sólo ${data.doceavo}{" "}
                  MXN,
                  <br />
                  además de tu inscripción cuatrimestral,
                </p>
                {/* NOTA: aqui debemos de cambiar la informacion de sextaFecha y crear una nueva con el nombre de sextaFechaDia */}
                <p style={{ color: "#ffffff" }}>
                  liquidando los primeros 10 días naturales del cuatrimestre.
                  <br />
                  Precios válidos para el calendario 2025 C, sujetos a cambios
                  {/* liquidando antes antes del{" "}
                  <br className="invisible_escritorio" />
                  <b>{data.sextaFecha}</b> <br />
                  Además de tu inscripción cuatrimestral. */}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="rein-fondo-6 contenedro-carrusle-1">
        <p id="banco" style={{ textAlign: "center" }}>
          Si te interesa pagar tu toda licenciatura{" "}
          <br className="invisible_escritorio" />
          completa contáctanos{" "}
          <a
            href="mailto:administracion@caav.mx"
            target="_blanck"
            style={{ color: "#b52c93" }}
          >
            <b>aquí</b>
          </a>
        </p>

        <div className="center container contenedor_movil_chico_re">
          <div className="row">
            <div
              style={{ background: "#B52C93" }}
              className="col s12 m10 l10 offset-m1 offset-l1 center rein-fondo-9"
            >
              <p
                style={{
                  color: "#ffffff",
                  fontWeight: "bold",
                  fontFamily: "Montserrat-Bold",
                }}
              >
                Costo de cada examen extraordinario: $1,500 MXN
              </p>
            </div>
          </div>
        </div>

        <h2
          className="titulo_4_nv"
          style={{
            fontFamily: "Montserrat-Bold",
            textAlign: "center",
          }}
        >
          Cómo y dónde pagar:
        </h2>
        <CarruselPagos />
      </section>

      <section
        style={{ background: "#ffffff", padding: "0" }}
        id="formulario"
        className="container row contenedor_movil_chico_re"
      >
        <div
          className="col s12 m10 l10 offset-m1 offset-l1"
          style={{
            marginTop: "25px",
          }}
        >
          <p
            className="titulo_4_nv center"
            style={{
              color: "#DE2657",
              fontFamily: "Montserrat-Bold",
              margin: "0",
            }}
          >
            Importante:
          </p>
          <p className="center">
            <b>
              El pago de tu inscripción <br className="invisible_escritorio" />
              no es reembolsable.
            </b>
            <br />
            <br className="invisible_escritorio" />
            Tu primera mensualidad
            <br className="invisible_escritorio" />
            es reembolsable <br />
            sólo si decides darte de baja{" "}
            <br className="invisible_escritorio" />
            dentro de los primeros <b>tres días </b>
            <br />
            de iniciado el ciclo escolar.
          </p>
          <h2 className="titulo_4_nv titulo-rein-1 center">
            Formulario de inscripción
          </h2>

          <p
            className="titulo_4_nv titulo_4_rein margin-bottom"
            style={{
              fontFamily: "Montserrat-Bold",
              margin: "0",
            }}
          >
            Quiero inscribirme a la licenciatura en:
          </p>
          <FormInscripcion />
        </div>
      </section>
    </>
  );
};

export default Inscripcion;
